import React from "react";
import CountUp from 'react-countup';
import { CounterSection } from "./counterstyle";

const Counter = () => {
  return (
    <>
      <CounterSection className="section">
      <section className="counter">
      <div className="container">
          <div className="counter-content">
              <div className="counter-part">
                  <div className="counter-area">
                      <div className="counter-number">
                          <h4><CountUp start={0} end={95} duration={2} suffix="%" enableScrollSpy={true} /></h4>
                          <p>on time delivery</p>
                      </div>
                      <div className="dot">
                          <span className="pink-dot"></span>
                      </div>
                      <div className="counter-number">
                          <h4><CountUp start={0} end={50} duration={2} suffix="+" enableScrollSpy={true} /></h4>
                          <p>Front-End Experts</p>
                      </div>
                      <div className="dot">
                          <span className="green-dot"></span>
                      </div>
                      <div className="counter-number">
                          <h4><CountUp start={0} end={10} duration={1} suffix="+" enableScrollSpy={true} /></h4>
                          <p>Years of Experience</p>
                      </div>
                      <div className="dot">
                          <span className="purple-dot"></span>
                      </div>
                      <div className="counter-number">
                          <h4><CountUp start={0} end={5} duration={1} suffix="+" enableScrollSpy={true} /></h4>
                          <p>Diverse Domains</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
      </CounterSection>
    </>
  );
};
export default Counter;
