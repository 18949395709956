import "./caseStudiespage.css";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { getCaseStudyData } from "../apiServices/apiServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../module/Header/header";
import { CaseStudiesMainHeader } from "../module/caseStudies/caseStudiesheaderStyle";
import Footer from "../module/footer/footer";
import Form from "../module/form/form";
import { getFullInfo } from "../apiServices/apiServices";
import { processProductHighlightsData } from "./utils"; // Assuming you have the function in a file called utils.js
import getImagePath from "../assets/ImageUtils";

const CaseStudiespage = ({ theme }) => {
  const { REACT_APP_PUBLIC_URL } = process.env;
  const [industryInfo, setIndustryInfo] = useState([]);
  useEffect(() => {
    fetchIndustryinfo();
  }, []);
  const fetchIndustryinfo = async () => {
    const Industriesdetails = await getFullInfo();

    setIndustryInfo(Industriesdetails);
  };

  /// Product title
  const [title, setTitle] = useState();
  //product intro
  const [titleIntro, setTitleIntro] = useState("");
  const [animate, setAnimate] = useState("");
  const params = useParams();
  // console.log("params", params);

  // storing data of custom pages
  const [pagedata, setPageData] = useState([]);

  useEffect(() => {
    fetchIndustryDetails();
  }, []);
  const fetchIndustryDetails = async () => {
    const Pagedata = await getCaseStudyData();
    setPageData(Pagedata);
  };

  ///geting the slug information
  const Pagetitle = params.title;

  let filtteredSlug = Pagetitle;
  filtteredSlug = filtteredSlug;
  let Data = pagedata.filter((element) => {
    return filtteredSlug === element.slug;
  });

  const MoreInfo = pagedata.filter(
    (element) => element.slug.toUpperCase != filtteredSlug
  );

  // about caseStudy
  const [about, setAbout] = useState("");
  //Client requirement description
  const [ClientRequirementsDescription, setClientRequirementsDescription] =
    useState("");
  //challenege description
  const [challenge, setChallenge] = useState("");
  // video url
  const [videoUrl, setVideoURL] = useState();
  /// Client feedback
  const [ClientFeedback, setClientFeedback] = useState("");
  //Client Image
  const [ClientImage, setClientImage] = useState("");
  // Product workdone points
  const [workdonePoints, setWorkDonePoints] = useState([]);
  // Product Specialization points
  const [specializationPoints, setspecializationPoints] = useState([]);
  // product images
  const [images, setImages] = useState([]);

  useEffect(() => {
    Data &&
      Data.map((element) => {
        setTitle(element?.headerTitle);
        setTitleIntro(element?.headerTitleIntro);
        setAbout(element?.introduction);
        setWorkDonePoints(element?.productWorkdonePoints);
        setspecializationPoints(element?.productSpecialization);
        setClientRequirementsDescription(element?.clientRequirements);
        setChallenge(element?.challenge);
        setVideoURL(element?.videoUrl);
        setClientFeedback(element?.clientFeedback);
        setClientImage(element?.productImages);
        setImages(element?.productSpecializationImages || []);

        console.log(element.productImages, "images>>>>>??????????????????");
      });
  }, [pagedata]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    console.log("HERE", title);
    scrollToTop();
  }, [params]);

  /// caseStudy gallery
  let box = document.querySelector(".CaseStudiesImages");
  const nextCaseStudy = () => {
    let width = box.clientWidth;
    new TransitionEvent((box.scrollLeft = box.scrollLeft + width));
  };
  const prevCaseStudy = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
  };
  /// clientFeedbacks slider
  let boxes = document.querySelector(".feedback");
  const nextFeedback = () => {
    let width = boxes.clientWidth;

    new TransitionEvent((boxes.scrollLeft = boxes.scrollLeft + width));
  };
  const prevFeedback = () => {
    let width = boxes.clientWidth;
    boxes.scrollLeft = boxes.scrollLeft - width;
  };
  const { productHighlightsDataNew } = Data && Data.length > 0 ? Data[0] : {};

  // let fields;
  // if (productHighlightsDataNew && productHighlightsDataNew.length > 0) {
  //   const [{ fields: extractedFields }] = productHighlightsDataNew;
  //   fields = extractedFields;
  // } else {
  //   fields = null; // or provide a default value
  // }

  // let appendedData = []
  // for (let i = 0; i <= 3; i++) {
  //   appendedData.push(fields)
  // }
  const data1 = Data;
  const appendedData = processProductHighlightsData(data1);
  console.log(appendedData, "appendedData1>>>>>>>>>>>>>>>>>", appendedData);

  const productHighlightsData =
    Data && Data.length > 0 && Data[0]?.productHighlightsData
      ? Object.entries(Data[0].productHighlightsData)
      : [];
  const keyHighlightChallenges =
    Data && Data.length > 0 && Data[0] && Data[0].keyHighlightChallenges
      ? Object.values(Data[0].keyHighlightChallenges)
      : [];
  const productSpecialization =
    Data && Data.length > 0 && Data[0]?.productSpecialization;
  const clientFeedbacks = Data && Data.length > 0 && Data[0]?.clientFeedbacks;
  // const productHighlightsDataNew = Data && Data.length > 0 && Data[0]?.productHighlightsDataNew ? Object.entries(Data[0].productHighlightsData) : [];

  console.log(productHighlightsData, "productHighlightsData");
  // console.log(productHighlightsData && (productHighlightsData), "productHighlightsData>>>>>>>>>>>>>>>>>>>")

  return (
    <>
      <CaseStudiesMainHeader className="header">
        <Header
          Title={Data && Data[0]?.headerTitle}
          Description={Data && Data[0]?.headerTitleIntro}
          image={Data[0]?.featuredImage.fields.file.url}
        />
      </CaseStudiesMainHeader>

      <div className="PageLayout">
        <div className="AboutCaseStudy">
          <svg
            width="107"
            height="134"
            viewBox="0 0 107 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "relative",
              top: "-36px",
              height: "7.5em",

              marginLeft: "-3em",
            }}
          >
            <path
              d="M38.2979 41.8711C59.7447 33.7713 83.7478 44.7255 91.8476 66.1724V66.1724C99.8915 87.4713 89.1966 111.392 67.8977 119.436V119.436C46.5988 127.48 22.8117 116.734 14.7678 95.4355L0.000670455 56.3347L38.2979 41.8711Z"
              fill="url(#paint0_linear_6_160)"
            />
            <path
              opacity="0.7"
              d="M81.4629 33C81.4629 14.7746 66.6883 0 48.4629 0V0C30.2375 0 15.4629 15.0227 15.4629 33.2481V33.2481C15.4629 51.7497 30.4613 67 48.9629 67H81.4629V33Z"
              fill="url(#paint1_linear_6_160)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6_160"
                x1="50.135"
                y1="41.5056"
                x2="56.5686"
                y2="122.731"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E9445F" />
                <stop offset="1" stop-color="#F27A3D" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_6_160"
                x1="48.3961"
                y1="66.8919"
                x2="48.3961"
                y2="14.5251"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#2D55DB" />
                <stop offset="1" stop-color="#B43E90" />
              </linearGradient>
            </defs>
          </svg>
          <h2>Introduction</h2>
          <p>{about}</p>
        </div>
        <div className="playVideo">
          <div className="VideoBgSvg">
            <section className="form-container">
              <div className="form-top-img">
                <img
                  className="form-img-1"
                  src={getImagePath("image/c_bg_t_1.svg")}
                  alt="img"
                />
                <img
                  className="form-img-2"
                  src={getImagePath("image/c_bg_t_2.svg")}
                  alt="img"
                />
              </div>
            </section>
          </div>
          <div className="Video">
            <ReactPlayer
              width="95%"
              height=""
              url={videoUrl}
              controls={true}
              className="videoframe"
            />
          </div>
        </div>
        <div className="ClientRequirements">
          <h2>Client Requirements:</h2>
          <p> {ClientRequirementsDescription}</p>
        </div>
        <div className="ProductKeyPoints">
          <svg
            width="516"
            height="589"
            viewBox="0 0 516 589"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: "absolute", marginTop: "16%" }}
          >
            <path
              opacity="0.1"
              d="M179.232 217.669C249.006 167.549 346.512 183.92 396.631 253.694V253.694C446.405 322.987 430.893 419.943 361.6 469.716V469.716C292.308 519.49 195.785 503.666 146.012 434.374L54.6373 307.166L179.232 217.669Z"
              fill="url(#paint0_linear_11_11)"
            />
            <path
              opacity="0.1"
              d="M326.186 143.516C308.559 77.7333 240.943 38.6952 175.161 56.3215V56.3215C109.379 73.9478 70.6505 142.72 88.2768 208.503V208.503C106.249 275.575 175.507 316.557 242.579 298.586L359.353 267.296L326.186 143.516Z"
              fill="url(#paint1_linear_11_11)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_11_11"
                x1="221.723"
                y1="204.865"
                x2="323.789"
                y2="492.632"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E9445F" />
                <stop offset="1" stop-color="#F27A3D" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_11_11"
                x1="239.897"
                y1="298.884"
                x2="189.029"
                y2="109.043"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#2D55DB" />
                <stop offset="1" stop-color="#B43E90" />
              </linearGradient>
            </defs>
          </svg>

          {/* <div className="point_container">

            {productHighlightsData && productHighlightsData.map((element) => { return <KeyPoints productData={element} /> })}

          </div> */}

          <div className="point_container">
            {productHighlightsDataNew?.length > 0 &&
              appendedData?.map((element) => {
                return <KeyPoints productData={element} />;
              })}
          </div>
        </div>
        <div className="Challenge">
          <h2>Challenge:</h2>
          <p>{challenge}</p>
        </div>
        <div className="WorkdoneLayout">
          <div className="ProductWorkDone">
            <div className="WorkedoneContainer">
              <h4>The Main Ones Where:</h4>
              <ul
                style={{
                  listStyle: "disc",
                  marginLeft: "4%",
                }}
              >
                {/* {keyHighlightChallenges && keyHighlightChallenges?.map((ele) => {
                  return <p>
                    {" "}
                    <li>
                      {ele}
                    </li>
                  </p>
                })} */}
              </ul>
            </div>
          </div>
          <svg
            width="107"
            height="134"
            viewBox="0 0 107 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              gridColumn: "2/1",
              gridRow: "1",
              marginLeft: "1em",
              marginTop: "5em",
            }}
          >
            <path
              d="M38.2979 41.8711C59.7447 33.7713 83.7478 44.7255 91.8476 66.1724V66.1724C99.8915 87.4713 89.1966 111.392 67.8977 119.436V119.436C46.5988 127.48 22.8117 116.734 14.7678 95.4355L0.000670455 56.3347L38.2979 41.8711Z"
              fill="url(#paint0_linear_6_160)"
            />
            <path
              opacity="0.7"
              d="M81.4629 33C81.4629 14.7746 66.6883 0 48.4629 0V0C30.2375 0 15.4629 15.0227 15.4629 33.2481V33.2481C15.4629 51.7497 30.4613 67 48.9629 67H81.4629V33Z"
              fill="url(#paint1_linear_6_160)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6_160"
                x1="50.135"
                y1="41.5056"
                x2="56.5686"
                y2="122.731"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E9445F" />
                <stop offset="1" stop-color="#F27A3D" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_6_160"
                x1="48.3961"
                y1="66.8919"
                x2="48.3961"
                y2="14.5251"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#2D55DB" />
                <stop offset="1" stop-color="#B43E90" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            width="107"
            height="134"
            viewBox="0 0 107 134"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              height: "7.5em",

              gridColumn: " 2",
              gridRow: "3",
              marginTop: "-5em",
            }}
          >
            <path
              d="M38.2979 41.8711C59.7447 33.7713 83.7478 44.7255 91.8476 66.1724V66.1724C99.8915 87.4713 89.1966 111.392 67.8977 119.436V119.436C46.5988 127.48 22.8117 116.734 14.7678 95.4355L0.000670455 56.3347L38.2979 41.8711Z"
              fill="url(#paint0_linear_6_160)"
            />
            <path
              opacity="0.7"
              d="M81.4629 33C81.4629 14.7746 66.6883 0 48.4629 0V0C30.2375 0 15.4629 15.0227 15.4629 33.2481V33.2481C15.4629 51.7497 30.4613 67 48.9629 67H81.4629V33Z"
              fill="url(#paint1_linear_6_160)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_6_160"
                x1="50.135"
                y1="41.5056"
                x2="56.5686"
                y2="122.731"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E9445F" />
                <stop offset="1" stop-color="#F27A3D" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_6_160"
                x1="48.3961"
                y1="66.8919"
                x2="48.3961"
                y2="14.5251"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#2D55DB" />
                <stop offset="1" stop-color="#B43E90" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="ProductSpecialization">
          {productSpecialization &&
            Object.keys(productSpecialization).map((ele, index) => {
              return (
                <>
                  <h2>{ele}</h2>
                  <ul
                    style={{
                      listStyle: "disc",
                      marginLeft: "1.5%",
                    }}
                  >
                    {productSpecialization &&
                      Object.values(productSpecialization)[index].map((ele) => {
                        return (
                          <p>
                            {" "}
                            <li>{ele}</li>
                          </p>
                        );
                      })}
                  </ul>
                </>
              );
            })}
        </div>

        {images && images.length > 0 && (
          <div className="ProductImages">
            <section
              className="form-container"
              style={{
                gridColumn: "1/3",
                gridRow: " 1/2",
                marginLeft: "83%",
                marginTop: "-4%",
                width: "20%",
              }}
            >
              <div className="form-top-img">
                <img
                  className="form-img-1"
                  src={getImagePath("image/c_bg_t_1.svg")}
                  alt="img"
                />
                <img
                  className="form-img-2"
                  src={getImagePath("image/c_bg_t_2.svg")}
                  alt="img"
                />
              </div>
            </section>

            <div className="TopBottomImages">
              <div className="TopImages">
                <div
                  className={
                    images.length < 2 ? "FullWidthImage" : "TopLeftImage"
                  }
                >
                  {images[0]?.fields?.file?.url && (
                    <img src={images[0].fields.file.url} alt="Top Left Image" />
                  )}
                </div>
                {images.length > 1 && (
                  <div className="TopRightImage">
                    {images[1]?.fields?.file?.url && (
                      <img
                        src={images[1].fields.file.url}
                        alt="Top Right Image"
                      />
                    )}
                  </div>
                )}
              </div>

              {/* Render BottomImage section if there are more than 2 images */}
              {images.length > 2 && images[2]?.fields?.file?.url && (
                <div className="BottomImage">
                  <img src={images[2].fields.file.url} alt="Bottom Image" />
                </div>
              )}
            </div>

            <section
              className="form-container"
              style={{
                gridColumn: "1/3",
                gridRow: " 1/2",
                marginTop: "48%",
                width: "20%",
              }}
            >
              <div className="form-top-img">
                <img
                  className="form-img-1"
                  src={getImagePath("image/c_bg_t_1.svg")}
                  alt="img"
                />
                <img
                  className="form-img-2"
                  src={getImagePath("image/c_bg_t_2.svg")}
                  alt="img"
                />
              </div>
            </section>
          </div>
        )}

        <div className="ClientFeedback">
          <h2>Client Feedback</h2>
          <div className="feedback">
            {clientFeedbacks &&
              clientFeedbacks.map((feedback) => {
                return (
                  <div className="allFeedbacks">
                    <div className="feedbackImage">
                      <svg
                        style={{
                          marginTop: "7%",
                          marginLeft: " 2em",
                          gridArea: "1 / 1 / 2 / 2",
                        }}
                        width="300"
                        height="300"
                        viewBox="0 0 286 251"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M115.784 8.33938C180.623 -16.1486 253.191 16.9693 277.679 81.809C301.998 146.202 269.665 218.52 205.272 242.839C140.88 267.158 68.9645 234.672 44.6454 170.28L0.00023837 52.0672L115.784 8.33938Z"
                          fill="url(#paint0_linear_87_46455)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_87_46455"
                            x1="151.57"
                            y1="7.23453"
                            x2="171.021"
                            y2="252.802"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#E9445F" />
                            <stop offset="1" stop-color="#F27A3D" />
                          </linearGradient>
                        </defs>
                      </svg>

                      <img
                        style={{
                          top: "50px",
                          width: "250px",
                          height: "250px",
                        }}
                        src={feedback?.fields?.file?.url}
                      ></img>
                    </div>
                    <div className="feedbackText">
                      <svg
                        style={{ marginTop: "10%" }}
                        width="29"
                        height="26"
                        viewBox="0 0 29 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.2053 25.247V16.0416C17.2053 11.8042 18.1185 8.29735 19.945 5.52112C21.8445 2.74488 24.5842 0.918407 28.1641 0.0417037V3.87729C25.9724 4.46176 24.4016 5.55764 23.4518 7.16494C22.5021 8.69918 21.9541 10.7083 21.808 13.1923H25.9724V25.247H17.2053ZM0.767059 25.247V16.0416C0.767059 11.8042 1.68029 8.29735 3.50677 5.52112C5.40629 2.74488 8.146 0.918407 11.7259 0.0417037V3.87729C9.53412 4.46176 7.96335 5.55764 7.01359 7.16494C6.06382 8.69918 5.51588 10.7083 5.36977 13.1923H9.53412V25.247H0.767059Z"
                          fill="#0E1D4B"
                        />
                      </svg>
                      <p>{feedback?.fields?.description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="navArrow">
          <svg
            onClick={prevFeedback}
            style={{
              cursor: "pointer",

              position: "absolute",
              right: "0",
              marginRight: "8em",
            }}
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
          >
            <circle
              cx="32"
              cy="32"
              r="31.5"
              transform="rotate(-180 32 32)"
              stroke="black"
            />
            <path
              d="M28.7598 40.0938L20.6664 32.0004L28.7598 23.9071"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M43.334 32L20.894 32"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            onClick={nextFeedback}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "0",
              marginRight: "3em",
            }}
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
          >
            <circle cx="32" cy="32" r="31.5" stroke="black" />
            <path
              d="M35.2402 23.9062L43.3336 31.9996L35.2402 40.0929"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.666 32H43.106"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <Form />
        <div className="MoreCaseStudies">
          <div className="MoreCaseStudiesHeader">
            <h2>More Case Studies</h2>
            <div className="Line"></div>
            <svg
              onClick={prevCaseStudy}
              style={{
                cursor: "pointer",
                color: "white",
                fill: theme == "dark" ? "white" : "",
                right: "0",
                marginRight: "1em",
                marginRight: "1%",
              }}
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
            >
              <circle
                cx="32"
                cy="32"
                r="31.5"
                transform="rotate(-180 32 32)"
                stroke="black"
              />
              <path
                d="M28.7598 40.0938L20.6664 32.0004L28.7598 23.9071"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M43.334 32L20.894 32"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              onClick={nextCaseStudy}
              style={{
                cursor: "pointer",
                fill: theme == "dark" ? "white" : "",
                right: "0",
              }}
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
            >
              <circle cx="32" cy="32" r="31.5" stroke="black" />
              <path
                d="M35.2402 23.9062L43.3336 31.9996L35.2402 40.0929"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.666 32H43.106"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="CaseStudiesImages">
            {industryInfo.map((element, index) => {
              {
                console.log(industryInfo, "industryInfo");
              }
              if (index < 3) {
                return (
                  <MoreCaseStudies
                    title={element?.headerTitle}
                    intro={element?.caseStudyDescription}
                    slug={element?.slug}
                    image={element?.featuredImage?.fields?.file?.url}
                    theme={theme}
                  />
                );
              }
            })}

            <div className="More">
              <Link to={"/case-study"}>
                <svg
                  style={{
                    cursor: "pointer",
                    fontWeight: "100",

                    right: "0",
                  }}
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                >
                  <circle cx="32" cy="32" r="31.5" stroke="black" />
                  <path
                    d="M35.2402 23.9062L43.3336 31.9996L35.2402 40.0929"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.666 32H43.106"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>

              <p>See All</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
const MoreCaseStudies = (props) => {
  const [pagedata, setPageData] = useState([]);
  useEffect(() => {
    fetchIndustryDetails();
  }, []);
  const fetchIndustryDetails = async () => {
    const Pagedata = await getCaseStudyData();
    setPageData(Pagedata);
  };
  const navigate = useNavigate();
  const { image } = props;
  /// Product title
  const [title, setTitle] = useState([]);
  //product intro
  const [titleIntro, setTitleIntro] = useState([]);
  useEffect(() => {
    setTitle(props.title);
    setTitleIntro(props.intro);
  }, []);
  const slugUrl = `/case-study/${props.slug}`;
  return (
    <div className="Images" style={{ backgroundImage: `url(${image})` }}>
      <div className="hover-content">
        <h1>{title}</h1>
        <div>
          <p>{titleIntro}</p>

          {/* "/case-study/" */}
          <div
            onClick={() => navigate(slugUrl && slugUrl)}
            style={{ marginTop: "3%" }}
          >
            <svg
              style={{
                marginBottom: "1%",

                fill: props.theme == "dark" ? "white" : "",
                cursor: "pointer",

                right: "0",
              }}
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="32" cy="32" r="31.5" stroke="white" />
              <path
                d="M35.2402 23.9062L43.3336 31.9996L35.2402 40.0929"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.666 32H43.106"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
const KeyPoints = ({ productData }) => {
  const {
    file: { url },
    title,
    description,
  } = productData;
  return (
    <div className="keypoints">
      <div className="pointLogo">
        <img style={{ height: "3em" }} src={url} alt={title} />
      </div>
      <div className="pointDescription">
        <p style={{ fontWeight: "800", fontSize: "20px" }}> {title}</p>
        <p style={{ marginTop: "3%" }}>{description}</p>
      </div>
    </div>
  );
};

export default CaseStudiespage;
