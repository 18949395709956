import React, {useEffect, useState, createContext} from "react";

export const switchContext = createContext();

const ThemeContext=({ children })=>{
const[theme, setTheme]=useState("light");
    
  const toggleTheme = (e) => {
    if (e.target.checked) {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    } else {
      localStorage.setItem("theme", "light");
      setTheme("light")
    }
  };
  
  const storedTheme = localStorage.getItem("theme");  
  
  const prefersDark =
    window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    
  const defaultDark = storedTheme === "dark" || (storedTheme === null && prefersDark);
    
  if (defaultDark) {
    localStorage.setItem("theme", "dark");
   }

  useEffect(()=>{
    if(storedTheme === "dark"){
      setTheme("dark");
    }else{
      setTheme("light");
    }
  },[storedTheme]);

  return(
    <switchContext.Provider value={{ theme, toggleTheme, defaultDark}} >
        {children}
    </switchContext.Provider>
    );
}
export default ThemeContext;