import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { ParallaxBanner } from "react-scroll-parallax";
import { BlogSection } from "./blogstyle";

const Blog = () => {
  return (
    <>
      <BlogSection className="stackBlogContainer">
        <div className="container">
          <ParallaxProvider>
            <ParallaxBanner
              className="banner-img"
              layers={[{ image: "./image/blog-img.png", speed: -20 }]}
              translateY={[-20, 20]}
            >
              <div className="stack-blog">
                <h3>Stack Blogs</h3>
                <p>
                  Stack Blog is an open community for anyone that codes. We help
                  you get answers to your toughest coding questions, share
                  knowledge with your coworkers in private.
                </p>
                <a href="https://blogs.stackui.tech/" target="_blank">
                  <button>See Blogs</button>
                </a>
              </div>
            </ParallaxBanner>
          </ParallaxProvider>
        </div>
      </BlogSection>
    </>
  );
};

export default Blog;
