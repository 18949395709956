import styled from "styled-components";
// import { theme } from "../../assets/styles/theme";
import { mq } from "../../assets/styles/index";

export const CaseStudiesMainHeader = styled.header`
    overflow: hidden;
    height: 100vh;
    .header-section {
        position: fixed;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 2;
        opacity: 0.9;
        padding: 3.3rem 0rem;
        transition: all 0.3s ease-out;
        .header-content {
            padding: 0px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            transition: all 0.3s ease-out;
            .right-content-header {
                cursor: pointer;
                display: flex;
                align-items: flex-end;
                padding: 0 0 0px 0;
                .header-logo {
                    margin-top: 1.375rem;
                    img {
                        &.header-logo1 {
                            width: 50px;
                            transition: all 0.3s ease-out;
                            transform: translate(0px, 0px);
                            animation: slideToTop 5s ease-in-out infinite;
                        }
                        &.header-logo2 {
                            width: 40px;
                            position: absolute;
                            margin: -1.375rem 0px 0px -2.75rem;
                            transition: all 0.3s ease-out;
                            transform: translate(0px, 0px);
                            animation: slideToBottom 5s ease-in-out infinite;
                        }
                    }
                }
                .stackuis {
                    padding-left: 0.5rem;
                    h3 {
                        font-size: 1.875rem;
                        font-style: normal;
                        font-weight: 400;
                        color: #FFFFFF;
                        span.ui {
                            color: #FD891E;
                        }
                    }
                }
            }
            .left-content-header {
                .header-menu {
                    display: flex;
                    nav {
                        display: flex;
                        div {
                            padding: 0rem 2rem;
                            .a {
                              font-weight: 600;
                              padding: 0rem;
                              font-size: 1rem;
                              line-height: 1.25;
                              color: #FFFFFF;
                              cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        &#header-sroll {
            &.small {
                padding: 0.75rem 0rem;
                background: linear-gradient(81.71deg ,#001d78 -2.91%,#2D55DB 80.82%);
                .header-content{
                    padding: 0px 0 0 0;
                }
                .header-logo {
                    margin-top: 18px;
                    .header-logo1 {
                        width: 35px;
                    }

                    .header-logo2 {
                        width: 30px;
                        position: absolute;
                        margin: -18px 0px 0px -33px;
                    }
                }
            }
        }
    }
    .ui-development {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        .box {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            .box-right {
                background: #2D55DB;
                border-radius: 109px;
                transform: rotate(25.06deg) translate(-400px, 0px);
                width: 35%;
                height: 187.57px;
                bottom: 292px;
                left: -131px;
                position: absolute;
                opacity: 0;
                animation: slideFromRight 1s ease-in-out 0.25s forwards;
            }

            .box-left {
                background: #6484EF;
                border-radius: 109px;
                transform: rotate(25.93deg) translate(400px, 0px);
                width: 49%;
                height: 187.57px;
                bottom: 408px;
                right: -183px;
                position: absolute;
                opacity: 0;
                animation: slideFromLeft 0.75s ease-in-out 0.25s forwards;
            }
            .box-left-white {
                background: #FFFFFF;
                border-radius: 109px;
                transform: rotate(25.93deg) translate(400px, 0px);
                width: 34%;
                height: 187.57px;
                bottom: 67px;
                right: -191px;
                position: absolute;
                opacity: 0;
                animation: slideFromLeft 1.5s ease-in-out 0.25s forwards;
            }
        }
        .ui-development-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
            position: relative;
            .ui-development-right {
                width: 50%;
                h1 {
                    font-weight: 600;
                    font-size: 5.3125rem;
                    line-height: 1.3;
                    color: #FFFFFF;
                }
                    p {
                    font-weight: 300;
                    word-spacing:3px;
                    }

            }
            .ui-development-left {
                width: 50%;
                opacity: 0;
                max-width: 50rem;
                position: relative;
                transform: translateX(50%);
                margin-top: 8%;
                transition: all 1.5s ease-in-out;
                img {
                    max-width: 100%;
                    transition: all 1.5s ease-in-out;
                    &.boyElement {
                        position: relative;
                        z-index: 1;
                    }
                    &.roomElement {
                        position: absolute;
                        opacity: 0;
                        transform: scale(0);
                    }
                    &.bricks {
                        right: 15%;
                        bottom: 54%;
                        ${mq.lessThan("xl", true)}{
                            width: 2.875rem;
                            right: 10%;
                            bottom: 45%;
                        }
                    }
                    &.flowerpot {
                        right: 0%;
                        top: 18%;
                        ${mq.lessThan("xl", true)}{
                            width: 6rem;
                        }
                    }
                    &.bookself {
                        right: 10%;
                        top: -9%;
                        ${mq.lessThan("xl", true)}{
                            width: 8rem;
                        }
                    }
                    &.painting {
                        right: 47%;
                        top: 0%;
                        ${mq.lessThan("xl", true)}{
                            width: 3rem;
                            top: -4%;
                        }
                    }
                }
            }
            &.animate {
                .ui-development-left {
                    transform: translateX(0%);
                    opacity: 1;
                    img {
                        &.roomElement {
                            animation: zoomIn 0.6s 2s forwards 1 linear, shakeY 3s 3.5s forwards infinite linear;
                            &.flowerpot {
                                animation: zoomIn 0.6s 2.3s forwards 1 linear, shakeX 4s 3.5s forwards infinite linear;
                            }
                            &.bookself {
                                animation: zoomIn 0.6s 2.6s forwards 1 linear, shakeX 3s 3.5s forwards infinite linear;
                            }
                            &.painting {
                                animation: zoomIn 0.6s 2.9s forwards 1 linear, shakeY 4s 3.5s forwards infinite linear;
                            }
                        }
                    }
                }
              }
        }
    }
}

@keyframes slideFromRight {
    0% {
        transform: rotate(25.06deg) translate(-400px, 0px);
        opacity: 0;
    }

    100% {
        transform: rotate(25.06deg) translate(0px, 0px);
        opacity: 1;
    }
}
@keyframes slideFromLeft {
    0% {
        transform: rotate(25.06deg) translate(400px, 0px);
        opacity: 0;
    }

    100% {
        transform: rotate(25.06deg) translate(0px, 0px);
        opacity: 1;
    }
}
@keyframes slideToTop {
    0% {
        transform: translate(0px, 0px);
        transform-origin: 0px 0px;
    }

    50% {
        transform: translate(0px, calc(-100% + 0.625rem));
    }

    100% {
        transform: translate(0px, 0px);
    }
}
@keyframes slideToBottom {
    0% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(0px, calc(100% - 1.25rem));
    }

    100% {
        transform: translate(0px, 0px);
    }
}

@media (max-width:768px) {
   nav{
    display:none !important;
   }
}

@media (max-width:600px) {
    .ui-development-content {
        display: flex;
        // flex-wrap: wrap;
    }
    .ui-development-right {
        width: 100% !important;
    }
    .ui-development-left {
        width: 100% !important;
        margin-top: 15% !important;
    }
}
`;
