import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";
import { mq } from "./index";

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: ${theme.fonts.defaultFont};
        min-height: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        ${mq.lessThan("lg", true)}{
            font-size: 14px;
        }
    }
    body {
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 100%;
        overflow-x: hidden;
        text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: "normal";
        
        // @font-face {
        //     font-family: 'PlusJakartaSans-Regular.woff' ;
        //     src: url('../public/plus_jakarta_sans/fonts/webfonts/PlusJakartaSans-Regular.woff');
        //   }

    }
 

    *{
        box-sizing: border-box;
    }
    *,
    ul,
    ol {
        outline: none;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ul,
    ol {
        list-style: none;
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        line-height: 1.5;
        // font-family: "Roboto";
    }

    input[type="text"]::-ms-clear {
        display: none;
    }

    ::-ms-clear {
        display: none;
    }

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        display: none;
    }

    select::-ms-expand {
        display: none;
    }

    a,
    li,
    p {
        font-size: ${theme.fonts.defaultFont};
    }

    a {
        text-decoration: none;
        outline: none;
    }
    body{
        ${mq.between("xs", "sm")}{
            background: #fff;
        }
        
    }
    .no-padding{
        padding:0px
    }

    .container {
        margin: 0 auto;
        padding: 0 50px;
        max-width: 1740px;
        width: 100%;
        ${mq.lessThan("xl", true)}{
            max-width: 1200px;
        }
        ${mq.lessThan("lg", true)}{
            max-width: 1024px;
        }
    }
    .d-flex {
        display: flex;
    }  
    @keyframes rotateClock {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(359deg);
        }
    }
    @keyframes rotateantiClock {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
    @keyframes zoomIn {
        0% {
            transform: scale(0);
            opacity: 0;
        }
        75% {
            transform: scale(1.2);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes shakeX {
        0% {
            transform: scale(1) translate(0rem, 0rem);
        }
        50% {
            transform: scale(1) translate(1rem, 0rem);
        }
        100% {
            transform: scale(1) translate(0rem, 0rem);
        }
    }
    @keyframes shakeY {
        0% {
            transform: scale(1) translate(0rem, 0rem);
        }
        50% {
            transform: scale(1) translate(0rem, 1rem);
        }
        100% {
            transform: scale(1) translate(0rem, 0rem);
        }
    }
    @media (max-width: 768px) {
        .d-flex {
            display: flex;
            flex-wrap: wrap;
        } 
    }
    @media (max-width: 600px) {
        .container {
            padding: 0 15px !important;
        }
     }
`;


