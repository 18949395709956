import React,{useContext} from "react";
import "./toggleStyle.css";
import { switchContext } from "../../context/themeContext";


const SwitchBtn =()=>{
const {toggleTheme, defaultDark} = useContext(switchContext);
return(
    <div className="toggle-theme-wrapper">
      <label className="toggle-theme" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
         defaultChecked={defaultDark}
        />
        <div className="slider round"></div>
      </label>
    </div>
)

}

export default SwitchBtn;