import { useState, useEffect } from "react";
import "./searchResult.css";
import { getCaseStudyData } from "../../apiServices/apiServices";

import { Link } from "react-router-dom";
const SearchResult = (props) => {
  const [pagedata, setPageData] = useState();
  console.log(props, "props>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
  /// extract the matched data
  useEffect(() => {
    fetchIndustryDetails();
  }, []);
  const fetchIndustryDetails = async () => {
    const Pagedata = await getCaseStudyData();
    setPageData(Pagedata);
  };
  ///for dark theme
  const storedTheme = localStorage.getItem("theme");
  const handleClick = () => {
    let filtteredSlug = props?.slug.replace(/-/g, " ");
    
    filtteredSlug = filtteredSlug?.toUpperCase();
    let Data = pagedata.filter((element) => {
      return filtteredSlug === element?.headerTitle.toUpperCase();
    });


    // props.sendData(props.slug, Data);
  };

  return (
    <>
      {storedTheme === "dark" ? (
        // <Router>
        <div className="ResultLayout">
          <div className="topLine"></div>
          <div className="resultContent" style={{ backgroundColor: "#181f3f" }}>
            <div className="IndexIndName">
              <div className="Index" style={{ color: "white" }}>
                {props.Index}
              </div>
              <div className="IndustryName" style={{ color: "wheat" }}>
                {props.IndustryName}
              </div>
            </div>
            <div className="Descriptionlink">
              <div className="ServiceDescription" style={{ color: "white" }}>
                {props.Description}{" "}
              </div>

              <Link to={"/case-study/" + props?.slug}>
                
                <div className="redirectArrow">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"

                  >
                    <circle cx="32" cy="32" r="31.5" stroke="white" />
                    <path
                      d="M35.2402 23.9062L43.3336 31.9996L35.2402 40.0929"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.666 32H43.106"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="ResultLayout">
          <div className="topLine"></div>
          <div className="resultContent">
            <div className="IndexIndName">
              <div className="Index"> {props?.Index}</div>
              <div className="IndustryName">{props?.IndustryName}</div>
            </div>
            <div className="Descriptionlink">
              <div className="ServiceDescription">{props?.Description}</div>
              <Link to={"/case-study/" + props?.slug}>
                <div className="redirectArrow" onClick={handleClick}>
                  <svg
                    style={{ cursor: "pointer" }}
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                  >
                    <circle
                      cx="32"
                      cy="32"
                      r="31.5"
                      fill="#FAFBFF"
                      stroke="black"
                    />
                    <path
                      d="M35.2402 23.9062L43.3336 31.9996L35.2402 40.0929"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.666 32H43.106"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SearchResult;
