import React from "react";
import { MainFooter } from "./footerstyle";
import getImagePath from "../../assets/ImageUtils";

const Footer = () => {
  const { REACT_APP_PUBLIC_URL } = process.env;
  return (
    <MainFooter className="footer">
      <div className="footer">
        <div className="container">
          <div className="stackui">
            <div className="stackpart">
              <div className="footer-logo">
                <img
                  className="footer-logo1"
                  src={getImagePath("image/Rectangle1.png")}
                  alt="img"
                />
                <img
                  className="footer-logo2"
                  src={getImagePath("image/Rectangle2.png")}
                  alt="img"
                />
              </div>
              <div className="stackuis">
                <h3>
                  Stack <span className="ui">UI</span>
                </h3>
              </div>
              <div className="stackpara">
                <p>
                  Stack UI is a software suite that provides state-of-the-art UI
                  development solutions for startups, small, medium, and large
                  enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-line"></div>
        <div className="footer-list">
          <div className="container">
            <div className="footer-item">
              <div className="footer-product">
                <h5>Products</h5>
                <ul>
                  <li>
                    <a href="javascript:void(0);">React UI Library</a>{" "}
                  </li>
                  <li>
                    <a href="javascript:void(0);">React Dashboard</a>{" "}
                  </li>
                  <li>
                    <a href="javascript:void(0);">Angular Library</a>{" "}
                  </li>
                  <li>
                    <a href="javascript:void(0);">Vue Library</a>{" "}
                  </li>
                </ul>
              </div>
              <div className="footer-tools">
                <h5>Free Tools</h5>
                <ul>
                  <li>
                    <a href="javascript:void(0);">Image Optimization</a>{" "}
                  </li>
                  <li>
                    <a href="javascript:void(0);">CSS Minification</a>{" "}
                  </li>
                  <li>
                    <a href="javascript:void(0);">JS Minification</a>{" "}
                  </li>
                </ul>
              </div>
              <div className="footer-contactus">
                <h5>Contact Us</h5>
                <ul>
                  <li>
                    <a href="mailto:hello@stackui.tech" rel="noreferrer">
                      Email: hello@stackui.tech
                    </a>{" "}
                  </li>
                  <li>
                    <a
                      href="https://www.google.com/maps/place/Successive+Technologies/@28.5423258,77.3401133,15z/data=!4m5!3m4!1s0x0:0x3afb9ba4d9a78de1!8m2!3d28.5423322!4d77.3401176"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Location: Windsor Grand 4th floor,
                      <br />
                      1- C, Sector 126, Noida,
                      <br /> Uttar Pradesh , 201313
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-img">
              <img
                className="footer-img1"
                src={getImagePath("image/Rectangle-3.png")}
                alt="img"
              />
              <img
                className="footer-img2"
                src={getImagePath("image/Rectangle-12.png")}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="container">
          <h6>Copyright @stackUI all right reserved</h6>
        </div>
      </div>
    </MainFooter>
  );
};

export default Footer;
