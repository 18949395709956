import styled from "styled-components";
import { theme } from "../../assets/styles/theme";

export const BlogSection = styled.section`
    .banner-img {
        width: 100%;
        position: relative;
        border-radius: 2.75rem;
        height: 25.625rem;
        // background: url('./image/blog-img.png') no-repeat;
        // background-attachment: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        .stack-blog {
            width: 50%;
            text-align: center;
            position: relative;
            h3 {
                font-weight: 600;
                font-size: 2.1875rem;
                line-height: 1.5;
                text-transform: capitalize;
                color: ${theme.colors.black.shade0};
            }
            p {
                font-size: 1.125rem;
                line-height: 1.8;
                text-align: center;
                text-transform: capitalize;
                color: ${theme.colors.black.shade0};
                padding: 1.5rem 0 0 0;
            }
            button {
                background: ${theme.backgroundColor.buttonBG};
                border-radius: 2.3125rem;
                border: 2px solid ${theme.backgroundColor.buttonBG};
                color: ${theme.colors.white.shade0};
                margin: 3.75rem auto 0px;
                padding: 0.875rem 4.125rem;
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.5;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    color: ${theme.backgroundColor.buttonBG};
                    background: ${theme.colors.white.shade0};
                }
            }
        }
    }
} 
@media (max-width: 768px) {
    .stack-blog {
        padding: 50px 0 50px 0;
    }
    .ba
    .stack-btn {
        padding: 48px 0 5px 0;
    }
    .stack-blog{
        width: 80% !important;
    }
}
@media (max-width: 500px) {
    .banner-img{
        height: auto !important;
    }
}
`;
