import React, { useContext } from "react";
import { Routes, Route, BrowserRouter as Routers } from "react-router-dom";
import Landing from "../module/landing/landing";
import { switchContext } from "../context/themeContext";
import { GlobalStyleLight } from "../assets/styles/globalStyleLight";
import { GlobalStyleDark } from "../assets/styles/globalStyleDark";
import Casestudies from "../module/caseStudies/caseStudies";
import CaseStudiespage from "../caseStudiespage/caseStudiespage";
import SearchResult from "../module/caseStudies/searchResult";
import { useState } from "react";

function Router() {
  const { theme } = useContext(switchContext);
  return (
    <>
      {theme === "light" ? <GlobalStyleLight /> : <GlobalStyleDark />}

      <Routes>
        <Route
          path="/case-study/:title"
          element={<CaseStudiespage theme={theme} />}
        ></Route>
        <Route path="/case-study" element={<Casestudies />} exact></Route>
        <Route path="/" element={<Landing theme={theme} />} exact></Route>
      </Routes>
    </>
  );
}

export default Router;
