import React from "react";
import { FormData } from "./formstyle";
import HubspotForm from "react-hubspot-form";
import getImagePath from "../../assets/ImageUtils";

const Form = () => {
  return (
    <>
      <FormData className="form form_section" id="contactus">
        <div className="container">
          <div className="form-heading">
            <h4>How can we help you</h4>
          </div>
          <section className="form-container">
            <div className="form-top-img">
              <img
                className="form-img-1"
                src={getImagePath("image/c_bg_t_1.svg")}
                alt="img"
              />
              <img
                className="form-img-2"
                src={getImagePath("image/c_bg_t_2.svg")}
                alt="img"
              />
            </div>
            <div className="form-bottom-img">
              <img
                className="form-btm-img-1"
                src={getImagePath("image/c_bg_b_1.svg")}
                alt="img"
              />
              <img
                className="form-btm-img-2"
                src={getImagePath("image/c_bg_b_2.svg")}
                alt="img"
              />
            </div>
            <div className="form-content">
              <div className="form-box">
                <HubspotForm
                  portalId="8904598"
                  formId="7ecf6d57-b46a-4601-80ac-65823877eca7"
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </section>
        </div>
      </FormData>
    </>
  );
};

export default Form;
