import React from "react";
import { FeatureSection } from "./featurestyle";
import getImagePath from "../../assets/ImageUtils";

const Feature = (props) => {
  const { theme } = props;
  return (
    <>
      <FeatureSection className="Section features_section" id="features">
        <div className="container">
          <div className="feature-heading">
            <h4>Popular Features</h4>
          </div>
          <div className="popular-feature-main">
            <div className="feature-group-icon">
              <div className="feature-icons1">
                {theme === "light" ? (
                  <img src={getImagePath("image/Vector-4.png")} alt="img" />
                ) : (
                  <img src={getImagePath("image/Vector-4d.png")} alt="img" />
                )}
              </div>
              <div className="feature-icons2">
                {theme === "light" ? (
                  <img src={getImagePath("image/user.png")} alt="img" />
                ) : (
                  <img
                    src={getImagePath("image/Combined-Shape (1).png")}
                    alt="Combined-Shape (1).png"
                  />
                )}
              </div>

              <div className="feature-list">
                <h5>Demos and training</h5>
                <p>
                  An array of product demos and training, including blogs and
                  documentation.
                </p>
                {/* <div className="blank-line">
                  <p>--------------------------</p>
                </div> */}
              </div>
            </div>
            <div className="feature-group-icon">
              <div className="feature-icons1">
                <img src={getImagePath("image/Vector-10.png")} alt="img" />
              </div>
              <div className="feature-icons2">
                {theme === "light" ? (
                  <img
                    className="performance"
                    src={getImagePath("image/performance.png")}
                    alt="img"
                  />
                ) : (
                  <img
                    className="performance"
                    src={getImagePath("image/launch.png")}
                    alt="launch"
                  />
                )}
              </div>
              <div className="feature-list">
                <h5>Performance</h5>
                <p>
                  An array of product demos and training, including blogs and
                  documentation.
                </p>
                {/* <div className="blank-line">
                  <p>--------------------------</p>
                </div> */}
              </div>
            </div>
            <div className="feature-group-icon">
              <div className="feature-icons1">
                {theme === "light" ? (
                  <img src={getImagePath("image/Vector-4.png")} alt="img" />
                ) : (
                  <img src={getImagePath("image/Vector 8.png")} alt="img" />
                )}
              </div>
              <div className="feature-icons2">
                {theme === "light" ? (
                  <img
                    className="support-icon"
                    src={getImagePath("image/support.png")}
                    alt="img"
                  />
                ) : (
                  <img
                    className="support-icon"
                    src={getImagePath("image/Combined-Shape (2).png")}
                    alt="img"
                  />
                )}
              </div>
              <div className="feature-list">
                <h5>Dedicated Support System</h5>
                <p>
                  An array of product demos and training, including blogs and
                  documentation.
                </p>
                {/* <div className="blank-line">
                  <p>--------------------------</p>
                </div> */}
              </div>
            </div>
            <div className="feature-group-icon">
              <div className="feature-icons1">
                {theme === "light" ? (
                  <img src={getImagePath("image/Vector-4.png")} alt="img" />
                ) : (
                  <img src={getImagePath("image/Vector 11.png")} alt="img" />
                )}
              </div>
              <div className="feature-icons2">
                {theme === "light" ? (
                  <img
                    className="ease"
                    src={getImagePath("image/ease.png")}
                    alt="img"
                  />
                ) : (
                  <img
                    className="ease"
                    src={getImagePath("image/Combined-Shape.png")}
                    alt="Combined-Shape.png"
                  />
                )}
              </div>
              <div className="feature-list">
                <h5>Ease of Use</h5>
                <p>
                  An array of product demos and training, including blogs and
                  documentation.
                </p>
                {/* <div className="blank-line">
                  <p>--------------------------</p>
                </div> */}
              </div>
            </div>
            <div className="feature-group-icon">
              <div className="feature-icons1">
                <img src={getImagePath("image/Vector-10.png")} alt="img" />
              </div>
              <div className="feature-icons2">
                {theme === "light" ? (
                  <img
                    className="free"
                    src={getImagePath("image/free.png")}
                    alt="img"
                  />
                ) : (
                  <img
                    className="free"
                    src={getImagePath("image/Combined-Shape (3).png")}
                    alt="img"
                  />
                )}
              </div>
              <div className="feature-list">
                <h5>Hassle-Free Licensing</h5>
                <p>
                  An array of product demos and training, including blogs and
                  documentation.
                </p>
              </div>
            </div>
            <div className="feature-group-icon">
              <div className="feature-icons1">
                {theme === "light" ? (
                  <img src={getImagePath("image/Vector-4.png")} alt="img" />
                ) : (
                  <img src={getImagePath("image/Vector-4d.png")} alt="img" />
                )}
              </div>
              <div className="feature-icons2">
                {theme === "light" ? (
                  <img
                    className="frequent"
                    src={getImagePath("image/Frequent-updates.png")}
                    alt="img"
                  />
                ) : (
                  <img
                    className="frequent"
                    src={getImagePath("image/Group-73-Copy-3 (1).png")}
                    alt="Path-68.png"
                  />
                )}
              </div>
              <div className="feature-list">
                <h5>Frequent Updates</h5>
                <p>
                  An array of product demos and training, including blogs and
                  documentation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </FeatureSection>
    </>
  );
};
export default Feature;
