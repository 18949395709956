import React from "react";
import CaseStudiesHeader from "./caseStudiesHeader";

const Casestudies = (props) => {
  return (
    <>
      <CaseStudiesHeader />
    </>
  );
};

export default Casestudies;
