import styled from "styled-components";
import {theme} from '../../assets/styles/theme';

export const LogoSection = styled.section`
    padding: 125px 0 51px 0;
    .logo {
        .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
            .logo-icons {
                text-align: center;
                img {
                    max-width: 95%;
                    max-height: 100%;
                    display: inline-block;
                }
                &.circle-image {
                    img {
                        max-width: 55%;
                    }
                }
            }
        }
        .slick-dots {
          li {
              width: 1rem;
              height: 1rem;
              button {
                  padding:  0px;
                  width: 1rem;
                  height: 1rem;
                  &:before {
                      content: '';
                      background: ${theme.colors.black.shade3};
                      border-radius: 50%;
                      width: 1rem;
                      height: 1rem;
                  }
              }
          }
      }
    }
} 
`;
