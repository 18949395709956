import React from "react";
import { Webdevelopment } from "./web-development-style";
import getImagePath from "../../assets/ImageUtils";

const Web = () => {
  return (
    <>
      <Webdevelopment className="section" id="aboutus">
        <section className="webdevelopment">
          <div className="container d-flex">
            <div className="left-content-web">
              <div className="web-heading">
                <h2>WHY WE</h2>
                <h3>Stack UI for easier and fastest</h3>
                <h4>web development</h4>
                <p>
                  Stack UI is a software suite that provides state-of-the-art UI
                  development solutions for startups, small, medium, and large
                  enterprises. It contains more than 500 components and
                  frameworks for JavaScript, React, Vue, and Angular that eases
                  developers’ work.
                </p>
              </div>
            </div>
            <div className="right-content-web">
              <div className="circle-group">
                <div className="web-logo">
                  <img
                    className="logo-img1"
                    src={getImagePath("image/Rectangle-3.png")}
                    alt="img"
                  />
                  <img
                    className="logo-img2"
                    src={getImagePath("image/Rectangle-12.png")}
                    alt="img"
                  />
                </div>
                <div className="circle inner-circle"></div>
                <div className="circle mid-circle">
                  <div className="dotsWrapper light-red">
                    <div className="dots"></div>
                    <div className="circle-title flexi">Flexible</div>
                  </div>
                  <div className="dotsWrapper light-green">
                    <div className="dots"></div>
                  </div>
                </div>
                <div className="circle outer-circle">
                  <div className="dotsWrapper light-green">
                    <div className="dots"></div>
                    <div className="circle-title cf">
                      Creative <br />
                      Function
                    </div>
                  </div>
                  <div className="dotsWrapper light-purple">
                    <div className="dots"></div>
                    <div className="circle-title etu">Easy to Use</div>
                  </div>
                  <div className="dotsWrapper light-blue">
                    <div className="dots"></div>
                    <div className="circle-title hqp">
                      High Quality <br /> Products
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Webdevelopment>
    </>
  );
};
export default Web;
