import styled from "styled-components";
import { theme } from "../../assets/styles/theme";

export const ProductSection = styled.section`
    padding: 8rem 0 0 0;
}
.product_heading {
    h4 {
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 1,3;
        text-transform: capitalize;
        color: ${theme.fonts.headingblack};
    }
}
.stack-ui {
    display: flex;
    justify-content: space-between;
    padding-top:1rem;
    .stack-ui-content {
        width: 40%;
        h5 {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.5;
            text-transform: capitalize;
            color: ${theme.fonts.headingblack};
            padding: 4rem 0rem 3.25rem 0rem;
        }

        p {
            font-weight: 400;
            font-size: 1.125rem;
            color:#333feature-list;
            line-height: 1.9;
        }
        .stack-ui-btn {
            padding: 5rem 0 0 0;  
            button {
                background: ${theme.backgroundColor.buttonBG};
                border-radius: 37px;
                font-weight: 700;
                font-size: 1.25rem;
                text-transform: uppercase;
                border: 2px solid ${theme.backgroundColor.buttonBG};
                color: ${theme.colors.white.shade0};
                padding: 1.25rem 2.75rem;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: ${theme.backgroundColor.buttonBG};
                    background: ${theme.colors.white.shade0};
                }
            }
        }
    }
}
.inner-img {
    position: relative;
    padding-bottom: 2rem;

    img {
        width: 28.125rem;
    }
}

img {
    &.inner-img-1 {
        animation-name: rotateClock;
        animation-duration: 40s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        position: relative;
        right: 5rem;
    }
    &.inner-img-2 {
        animation-name: rotateantiClock;
        animation-duration: 40s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        position: absolute;
        top: 0.25rem;
        right: 6rem;
    }

    &.outer-img-2 {
        position: absolute;
        top: 9.9375rem;
        width: 30.5rem;
        left: -5.5625rem;
    }

    &.outer-img-1 {
        position: absolute;
        top: 4.875rem;
        left: -11.875rem;
        width: 30.5rem;
    }
}

.slick-dots {
    li {
        width: 1rem;
        height: 1rem;
        button {
            padding:  0px;
            width: 1rem;
            height: 1rem;
            &:before {
                content: '';
                background: ${theme.colors.black.shade3};
                border-radius: 50%;
                width: 1rem;
                height: 1rem;
            }
        }
    }
}


@media (max-width: 768px) {
    .stack-ui-content {
        width: 100% !important;
    }
    .stack-ui {
      display: block;
    }
}
@media (max-width:600px) {
    img.outer-img-2 {
        width: 26.5rem !important;
        left: 33px !important;
    }
    img.inner-img-1 {
        left: 53px !important;
    }
    img.inner-img-2 {
        left: 57px !important;
    }
    .inner-img img {
        width: 18.125rem !important;
    }
    img.outer-img-1 {
        left:0rem !important;
    }
    .slider-part {
        padding-top: 50px;
        width:70%;
        margin 0 auto;
    }
    .right-side-img{
        position: absolute;
        top: 23px;
        bottom: 0;
    }
    .stack-ui{
        position: relative;
    }
     .stack-ui-content{
        margin-top: 66%;
     }
}
@media (max-width:500px) {
    .slider-part {
        padding-top: 50px;
        width:100%;
        margin 0 auto;
    }
    .inner-img img{
        /* width: 14.125rem !important;
        margin: 0 auto;
        padding: 0 10px 0 20px; */
        max-width: 70%;
    /* margin: 0 auto; */
    overflow: hidden;
    width: 100%;
    }
    .right-side-img {
    margin: 0 auto;
    width: 70%;
}
}
@media (max-width: 390px) {
    .inner-img img{
        /* width: 14.125rem !important;
        margin: 0 auto;
        padding: 0 10px 0 20px; */
        /* max-width: 58%;
    margin: 0 auto;
    overflow: hidden;
    width: 100%; */
    }
    .right-side-img {
    margin: 0 auto;
    width: 70%;
}

}
@media (min-width: 600px) and (max-width: 768px) {
    .inner-img img{
        width: 24rem;
    }
    .right-side-img {
    margin: 0 auto;
    width: 60%;
    padding: 37px 0 32px 0;
}
img.inner-img-1{
    right: 0;
}
img.inner-img-2{
    right: 0;
}
img.outer-img-1{
   left: -3.875rem;
}
img.outer-img-2{
    left : 0.4375rem;
}
}
 ;`