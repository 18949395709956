import styled from "styled-components";
import { mq } from "../../assets/styles/index";

export const Webdevelopment = styled.section`
    background: #FAFBFF;
    overflow: hidden;
} 
.container {
    justify-content: space-between;
    align-items: center;
    padding: 5rem 3.125rem 10rem;
}
.left-content-web {
    width: 50%;
    padding-right: 2rem;
    .web-heading {
        width: 100%;
        h2{
            font-weight: 400;
            font-size: 1.3125rem;
            text-transform: uppercase;
            color: #FD891E;
        }
        h3{
            font-weight: 800;
            font-size: 2.5rem;
            text-transform: capitalize;
            color: #0E1D4B;
            padding: 0.75rem 0 0 0;
        }
        h4 {
            font-weight: 800;
            font-size: 2.5rem;
            text-transform: capitalize;
            color: #2D55DB;
        }
        p{
            font-weight: 400;
            font-size: 1.125rem;
            text-transform: capitalize;
            color: #333;
            line-height: 1.8;
            padding: 2.25rem 6rem 0 0;
        }
    }
}

.right-content-web {
    .circle-group {
        position: relative;
        width: 34.5rem;
        height: 34.5rem;
        border: 1px solid #D0D0D0;
        border-radius: 50%;
        right: 100px;
        top: 11px;
        ${mq.lessThan("xl", true)}{
            right: 0px;
        }
        .circle {
            border: 1px solid #D0D0D0;
            border-radius: 50%;
            animation-name: rotateClock;
            animation-duration: 60s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            position: relative;
            z-index: 1;
            &.outer-circle {
                width: 34.5rem;
                height: 34.5rem;
                border: 1px solid transparent;
            }
            &.mid-circle {
                width: 23.625rem;
                height: 23.625rem;
                position: absolute;
                top: calc(50% - 11.8125rem);
                left: calc(50% - 11.8125rem);
                .dotsWrapper {
                    &.light-green {
                        bottom: 6.3rem;
                        left: auto;
                        right: 0.75rem;
                        animation: none;
                        .dots {
                            width: 0.75rem;
                            height: 0.75rem;
                            background: #9FFFC5;
                        }
                    }
                }
            }
            &.inner-circle {
                width: 14.125rem;
                height: 14.125rem;
                position: absolute;
                top: calc(50% - 7.0625rem);
                left: calc(50% - 7.0625rem);
            }
            .dotsWrapper {
                position: absolute;
                transform-origin: 6px 6px;
                animation-name: rotateantiClock;
                animation-duration: 60s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                .dots {
                    border-radius: 50%;
                }
                .circle-title {
                    box-shadow: 0px 4px 13px rgba(100, 132, 239, 0.08);
                    border-radius: 1.25rem;
                    background: #ffffff;
                    font-size: 1.25rem;
                    padding: 1.5rem;
                }
                &.light-green {
                    bottom: 5.25rem;
                    left: 0.25rem;
                    z-index: 1;
                    .dots {
                        width: 0.75rem;
                        height: 0.75rem;
                        background: #9FFFC5;
                    }
                    .circle-title {
                        &.cf {
                            border: 1px solid #9FFFC5;
                            margin-top: 0.75rem;
                            margin-left: -5.25rem;
                        }
                    }
                }
                &.light-purple {
                    bottom: -2.1rem;
                    right: 4.5rem;
                    z-index: 1;
                    transform-origin: 9px 9px;
                    .dots {
                        width: 1.125rem;
                        height: 1.125rem;
                        background: #E59AFF;
                        margin-bottom: 0.35rem;
                    }
                    .circle-title {
                        &.etu {
                            border: 1px solid #E59AFF;
                            margin-left: -7rem;
                        }

                    }
                }
                &.light-blue {
                    top: 2rem;
                    right: 1.5rem;
                    z-index: 1;
                    transform-origin: 9px 9px;
                    .dots {
                        width: 1.125rem;
                        height: 1.125rem;
                        background: #90A4E8;
                        margin-bottom: 1rem;
                    }
                    .circle-title {
                        &.hqp {
                            border: 1px solid #90A4E8;
                            margin-left: -4.5rem;
                            padding: 0.85rem 2.1rem;
                        }
                    }
                }
                &.light-red {
                    top: 2rem;
                    left: 4.5rem;
                    transform-origin: 5px 5px;
                    .dots {
                        width: 0.5625rem;
                        height: 0.5625rem;
                        background: #FF999A;
                        margin-bottom: 0.5rem;
                    }
                    .circle-title {
                        &.flexi {
                            border: 1px solid #FF999A;
                            padding: 1.5rem 1.75rem;
                            margin-left: -7rem;
                        }
                    }
                }
            }
        }
        .web-logo {
            position: absolute;
            left: 50%;
            top: calc(50% + 1.4rem);
            transform: translate(-50%, -50%);
            .logo-img1 {
                width: 6.5rem;
            }
            .logo-img2 {
                width: 4.75rem;
                position: absolute;
                right: 1rem;
                bottom: 4rem;
            }

        }
    }
}
@media (max-width: 768px) {
    .right-content-web {
        display: block;
        margin: 50px auto 0;
    }
    .web-heading {
        width: 100%;
        padding: 49px 0 0 0;
    }
    .web-para p {
        max-width: 100%;
    }
    .web-heading h4 {
        width: 94%;
    }
    .left-content-web{
        width:100%;
    }
}
@media (max-width: 700px) {
     .right-content-web {
         display: none;
     }
     .container {
        justify-content: space-between;
        align-items: center;
        padding: 5rem 3.125rem 0rem;
    }
 }
@media (max-width: 600px) {
   .web-heading p {
        padding: 2.25rem 0rem 0 0 !important;
    }
}
}
;`;
