import styled from "styled-components";
import { theme } from "../../assets/styles/theme";

export const FormData = styled.section`
    background: #FAFBFF;
    padding-bottom: 5.5rem;
    .form-heading {
        h4 {
            font-weight: 800;
            font-size: 2.5rem;
            line-height: 1.5;
            color: ${theme.fonts.headingblack};
            padding: 3.4375rem 0 1.875rem 0;
        }
    }
    .form-container {
        width: 100%;
        position: relative;
        .form-top-img {
            width: 30.5rem;
            position: absolute;
            left: 0px;
            top: -1.25rem;
            img {
                max-width: 100%;
                animation-name: rotateClock;
                animation-duration: 40s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                &.form-img-2 {
                    animation-name: rotateantiClock;
                    position: absolute;
                    left: 4rem;
                    top: -3rem;
                }
            }
        }
  
        .form-bottom-img {
            width: 30.5rem;
            position: absolute;
            right: 6rem;
            bottom: -3rem;
            img {
                max-width: 100%;
                animation-name: rotateClock;
                animation-duration: 40s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                &.form-btm-img-1 {
                    animation-name: rotateantiClock;
                    position: absolute;
                    bottom: 5rem;
                    right: -1rem;
                }
            }
        }
        form#hsForm_7ecf6d57-b46a-4601-80ac-65823877eca7 {
            display: flex;
            flex-wrap: wrap;
            vertical-align: top;
            align-items: flex-start;
        }
        .form-content {
            background: ${theme.colors.white.shade0};
            box-shadow: 0px 4px 59px rgba(45, 85, 219, 0.09);
            border-radius: 1.25rem;
            width: 81%;
            margin: 0 auto;
            margin-top: 4.75rem;
            position: relative;
            padding: 3.25rem 4rem;
            .form-box {
                // display: flex;
                // justify-content: space-between;
                // width: 100%;
                .form-field {
                    // width: 48%;
                }
            }
            .form-mail {
                padding: 1.375rem 0 0 0;
            }
            label {
                display: block;
                font-weight: 700;
                font-size: 1.125rem;
                line-height: 1.5;
                color: ${theme.fonts.label};
                padding-bottom: 1rem;
            }
            .input input {
                width: 100%;
                padding-left: 1rem;
                border: 1px solid rgba(100, 116, 139, 0.26);
                border-radius: 0.5rem;
                height: 3.75rem;
                width: 100%;
                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    color: ${theme.fonts.inputPlaceHolder};
                }
            }
            .input textarea {
                width: 100%;
                padding: 1rem;
                border: 1px solid rgba(100, 116, 139, 0.26);
                border-radius: 0.5rem;
                height: 7rem;
                width: 100%;
                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    color: ${theme.fonts.inputPlaceHolder};
                }
            }
            .hs_error_rollup{
                flex-basis: 100%;
            }
            .no-list.hs-error-msgs.inputs-list .hs-error-msg, .hs-main-font-element {
                color: red;
                font-size: 12px;
                margin: 5px 0 0 0;
                font-weight: normal;
            }
            
            .hs_submit.hs-submit {
                padding: 2rem 0 0 0;
                input {
                    background: ${theme.backgroundColor.buttonBG};
                    border-radius: 2.25rem;
                    font-weight: 700;
                    font-size: 1.25rem;
                    line-height: 1.5;
                    text-transform: uppercase;
                    color: ${theme.colors.white.shade0};
                    cursor: pointer;
                    border: 2px solid ${theme.backgroundColor.buttonBG};
                    width: 15.5rem;
                    height: 4.25rem;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        color: ${theme.backgroundColor.buttonBG};
                        background: ${theme.colors.white.shade0};
                    }
                }
            }
            .hs_phone.hs-phone.hs-fieldtype-phonenumber.field.hs-form-field
            {
                width: 45%;
                display: inline-block;
                margin-left: 4%;
                padding-bottom:1.375rem;
            }
            .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field{
                width: 45%;
                display: inline-block;
                padding-bottom:1.375rem;
               
            }
            .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field{
                padding-bottom:1.375rem;  
                width: 100%; 
            }
            .hs_message.hs-message.hs-fieldtype-textarea.field.hs-form-field {
                width: 100%;
            }
        }
    }
} 

@media (max-width: 768px) {
    .form-btn button {
        width: 43%;
    }
    input.form-control {
        width: 100%;
        padding-left: 13px;
    }
    input::placeholder {
        font-size: 12px;
    }
    .form-content{
        width:100% !important;
    }
}
@media (max-width:600px) {
    .form-top-img {
        width: 20.5rem !important;
    }
    .form-bottom-img {
        width: 20.5rem !important;
    }
    .form-content {
        padding: 3.25rem 12px !important;
    }
   .form-content .input textarea {
        padding-left:8px !important;
    }
    .form-content .input input {
        padding-left:8px !important;
    }
    .form-container .form-content .hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
        width: 100% !important;
    }
    .form-container .form-content .hs_phone.hs-phone.hs-fieldtype-phonenumber.field.hs-form-field {
        width: 100% !important;
        margin-left:0%;

    }
    .form-container .form-content .hs_phone.hs-phone.hs-fieldtype-text.field.hs-form-field {
        width: 100% !important;
        margin-left:0%;
    }
}
`;
