export const processProductHighlightsData = (data) => {
    const { productHighlightsDataNew } = data && data.length > 0 ? data[0] : {};
    let fields;
    if (productHighlightsDataNew && productHighlightsDataNew.length > 0) {
      const [{ fields: extractedFields }] = productHighlightsDataNew;
      fields = extractedFields;
    } else {
      fields = null; // or provide a default value
    }
  
    const appendedData = [];
    for (let i = 0; i <= 3; i++) {
      appendedData.push(fields);
    }
  
    console.log(appendedData, "appendedData");
  
    return appendedData;
  };
  