import React, { useState, useRef, useEffect } from "react";
import { CaseStudiesMainHeader } from "./caseStudiesheaderStyle";
import "./caseStudy.css";
import SearchIcon from "@mui/icons-material/Search";
import SwitchBtn from "../../component/ToggleBtn/switch";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchResult from "./searchResult";
import {
  getIndustries,
  getFullInfo,
  getServices,
} from "../../apiServices/apiServices";
import CloseIcon from "@mui/icons-material/Close";
import ScrollIntoView from "react-scroll-into-view";
import Header from "../Header/header";
import Footer from "../footer/footer";

const CaseStudiesHeader = () => {
  const [a, setA] = useState();
  const [industryinfo, setIndustryInfo] = useState([]);

  const [industryclosebtn, setIndustryClosebtn] = useState(false);
  const [industryDetails, setIndustryDetails] = useState([]);
  const [caseStudyList, setCaseStudyList] = useState([])
  const [data, setData] = useState([])
  const [getIndustryValue, setIndustryValue] = useState("");
  const [getServiceValue, setServiceValue] = useState("");
  const [getSearch, setResult] = useState("Search");
  const [serviceclosebtn, setServiceClosebtn] = useState(false);
  const [industryclose, setIndustryClose] = useState(0);
  const [serviceclose, setServiceClose] = useState(0);
  const [SelectedIndustryFilter, setSelectedIndustryFilter] = useState("");
  const [SelectedServiceFilter, setSelectedServiceFilter] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [filteredResult, setFilteredResult] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const Industryref = useRef(null);
  const Serviceref = useRef(null);
  const storedTheme = localStorage.getItem("theme");
  const [selectServicesArrow, setselectServicesArrow] = useState(true);
  const [selectServicesDropdwon, setselectServicesDropdwon] = useState(false);
  //   select Industry drop down
  const [selectIndustryArrow, setselectIndustryArrow] = useState(true);
  const [selectIndustryDropdwon, setselectIndustryDropdwon] = useState(false);

  const [animate, setAnimate] = useState("");

  // filter titles
  const [selectIndustrytitle, setSelectIndustryTitle] =
    useState("Select Industry");
  const [selectServicetitle, setServiceTitle] = useState("Select Service");
  const handleSelect = (e)=> {
    e.stopPropagation();

    setselectIndustryDropdwon(!selectIndustryDropdwon)
    setselectIndustryArrow(!selectIndustryArrow)
  }


 
   
  
  useEffect(() => {
    if (SelectedIndustryFilter !== "") {
      setFilteredResult(true);
      setIndustryClosebtn(true);
      const update = industryinfo.filter((element) => {
        return SelectedIndustryFilter === element.industryType;
      });

      const updatedServiceList = update.map((element, index) => {
        return element.serviceType;
      });

      setServiceType([...new Set(updatedServiceList)]);

      if (SelectedServiceFilter !== "") {
        const ServiceResult = update.filter((element) => {
          return element.serviceType === SelectedServiceFilter;
        });

        setA(ServiceResult);


        setServiceClosebtn(true);
      } else {
        const IndustryResult = industryinfo.filter((element) => {
          return element.industryType === SelectedIndustryFilter;
        });
        setA(IndustryResult);
      }
    } else if (SelectedServiceFilter !== "") {
      setServiceClosebtn(true);
      setFilteredResult(true);

      const ServiceResult = industryinfo.filter((element) => {
        return element.serviceType === SelectedServiceFilter;
      });
      setA(ServiceResult);

    } else {
      setServiceType(serviceTypes);

      setFilteredResult(false);
    }
  }, [SelectedIndustryFilter, SelectedServiceFilter]);
  useEffect(() => {
    setSelectedIndustryFilter("");

    setSelectIndustryTitle("Select Industry");
    setIndustryClosebtn(false);
  }, [industryclose]);
  useEffect(() => {
    setSelectedServiceFilter("");
    setServiceTitle("Select Service");

    setServiceClosebtn(false);
  }, [serviceclose]);
  // fetch IndsutryDetails from contentful
  useEffect(() => {
    fetchIndustryinfo();
  }, []);
  const [industryinfos, setIndsutryinfos] = useState();
  const fetchIndustryinfo = async () => {
    const Industriesdetails = await getFullInfo();
    setData(Industriesdetails)
    setCaseStudyList(Industriesdetails)

    setIndustryInfo(Industriesdetails);
    setIndsutryinfos(Industriesdetails);
  };

  console.log(data, "industryInfo>>>>>>>>>>>>>>>>>>")

  // fetch IndsutryTypeDetails from contentful

  // fetch IndsutryType from contentful


  //to detect which Indsutry is selected
  var FilteredService;

  useEffect(() => {


    const filteredList = data?.filter(ele =>
      ele?.industryType?.toUpperCase().includes(getIndustryValue.toUpperCase()) && ele.serviceType.toUpperCase().includes(getServiceValue.toUpperCase()) && ele.caseStudyName.toUpperCase().includes(searchValue.toUpperCase())
    );
    console.log(data, filteredList, getServiceValue, getIndustryValue, searchValue, "Testing>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    setCaseStudyList(filteredList || []);
  }, [getServiceValue, getIndustryValue, searchValue]);

  const handleCloseDropdown = (e) => {
    !Serviceref.current.contains(e.target) && setselectServicesDropdwon(false);
    !Industryref.current.contains(e.target) && setselectIndustryDropdwon(false)
  }

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdown,);
    return () => {
      document.removeEventListener("click", handleCloseDropdown)
    }
  }, []);




  console.log(data, "data>>>>>>>>>>>>>>>>>>>>>>>>>")


  const IndustryContent = (prop) => {
    const { IndustryName } = prop

    return (<div
      onClick={() => [
        setselectIndustryArrow(!selectIndustryArrow),
        setselectIndustryDropdwon(false),
        setSelectedIndustryFilter(IndustryName),
        setSelectIndustryTitle(IndustryName),
        setIndustryValue(IndustryName)
      ]}
      className="selectIndustryItems"
      style={{
        backgroundColor: storedTheme === "dark" ? "rgb(24 31 63)" : "inherit",
        color: storedTheme === "dark" ? "white" : "inherit",
      }}
    >
      <p>{IndustryName === "" ? "Select Industry" : IndustryName}</p>
    </div>
    )

  };

  const ServiceContent = ({ ServiceName }) => {

    return (
      <div
        className="selectServiceItems"
        onClick={() => [
          setselectServicesArrow(!selectServicesArrow),
          setselectServicesDropdwon(false),
          setSelectedServiceFilter(ServiceName),
          setServiceTitle(ServiceName),
          setServiceValue(ServiceName)
        ]}
        style={{
          backgroundColor: storedTheme === "dark" ? "rgb(24 31 63)" : "inherit",
          color: storedTheme === "dark" ? "white" : "inherit",
        }}
      >
        <p>{ServiceName === "" ? "Select Industry" : ServiceName}</p>
      </div>
    )

  };

  return (
    <>
      <CaseStudiesMainHeader className="header" >
        <Header />
        <section className="ui-development" id="home">
          <div className="box">
            <div className="box-right"></div>
            <div className="box-left"></div>
            <div className="box-left-white"></div>
          </div>
          <div className="container">
            <div className={"ui-development-content"}>
              <div
                className="ui-development-right"
                style={{ cursor: "default" }}
              >
                <h1>
                  Case Studies
                  <p>
                    Our Peaople Build Extraordinary Products. Over The Course of
                    Recent Years, Our Tech Experts Supported Dozen of Companies
                    Accross Various Markets And Industries. Read These Stories
                    And Learn, What They Can Do For Your Business.
                  </p>
                </h1>
              </div>
              <div className="ui-development-left">
                {/* <img
                  className="roomElement painting"
                  src="./image/E1.svg"
                  alt="img"
                />
                <img
                  className="roomElement bookself"
                  src="./image/E2.svg"
                  alt="img"
                />
                <img
                  className="roomElement flowerpot"
                  src="./image/E3.svg"
                  alt="img"
                />
                <img
                  className="roomElement bricks"
                  src="./image/E4.svg"
                  alt="img"
                />
                <img className="boyElement" src="./image/tab.png" alt="img" /> */}
              </div>
            </div>
          </div>
        </section>
      </CaseStudiesMainHeader>


      <div
        className="caseStudyMainContent"
        style={(storedTheme === "dark") ? { backgroundColor: "#181f3f" } : {}}
      >
        <div
          className="caseStudyNavbar"
          style={(storedTheme === "dark") ? { backgroundColor: "#181f3f" } : { backgroundColor: "white" }}
        >
          <div className="caseStudyNavContent1">
            <div
              className="navSelectIndustryDropdown"
              onClick={(e)=>{
                handleSelect(e)
              }}
              
              ref={Industryref}

            >
              <p>{selectIndustrytitle}</p>
              {!selectIndustryArrow ? (
                <KeyboardArrowUpIcon style={(storedTheme === "dark") ? { color: "white" } : {}} />
              ) : (
                <KeyboardArrowDownIcon style={(storedTheme === "dark") ? { color: "white" } : {}} />
              )}
            </div>
            {industryclosebtn && (
              <CloseIcon
                onClick={() => [setIndustryValue(""), setIndustryClose(industryclose + 1)]}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "20px",
                  color: storedTheme == "dark" ? "white" : "inherit"
                  // color: storedTheme =="dark"? "white":"inherit",
                }}

              />
            )}
            {selectIndustryDropdwon && (
              <div className="selectIndustryContent">
                {data &&
                  data.map((element, index) => {
                    return (
                      <IndustryContent
                        IndustryName={element.industryType}
                        keys={index}
                        key={index}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          <div className="caseStudyNavContent2">
            <div
              className="caseStudyNavdropdown"
              onClick={(e) => {
                e.stopPropagation()
                setselectServicesArrow(!selectServicesArrow)
                setselectServicesDropdwon(!selectServicesDropdwon)
              }
                
              }
              ref={Serviceref}
            >
              <p>{selectServicetitle}</p>
              {!selectServicesArrow ? (
                <KeyboardArrowUpIcon style={(storedTheme === "dark") ? { color: "white" } : {}} />
              ) : (
                <KeyboardArrowDownIcon style={(storedTheme === "dark") ? { color: "white" } : {}} />
              )}

              {/* style={(storedTheme === "dark") ?  {color: "white" }: ""} */}
            </div>
            {serviceclosebtn && (
              <CloseIcon
                onClick={() => [setServiceClose(serviceclose + 1), setServiceValue("")]}
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "20px",
                  color: storedTheme == "dark" ? "white" : "inherit",

                }}
              />
            )}
            {selectServicesDropdwon && (
              <div className="selectServiceContent" >
                {data &&
                  (data.map((element, index) => {
                    return (
                      <ServiceContent
                        key={index}
                        keys={index}
                        ServiceName={element.serviceType}
                      />
                    );
                  }))}
              </div>
            )}
          </div>
          <div className="caseStudyNavContent3">

            <input
              style={{ color: storedTheme == 'dark' ? "white" : "black" }}
              className="caseStudySearchbar"
              value={searchValue}
              placeholder="Search"
              // onClick={() => {
              //   if (searchValue === "Search") {
              //     setSearchValue("");
              //   }
              // }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            ></input>

            <SearchIcon
              style={{
                height: "35px",
                width: "2em",
                marginTop: "2px",
                cursor: "pointer",
                color: storedTheme == "dark" ? "white" : "inherit"
              }
              }
            />
          </div>
        </div>
        {caseStudyList &&
          caseStudyList.map((element, index) => {
            return (
              <SearchResult
                key={index}
                Index={index + 1}
                IndustryName={element.caseStudyName}
                Description={element.description}
                slug={element.slug}
              />
            );
          })}

      </div>

      <Footer />

    </>
  );
};

export default CaseStudiesHeader;
