import React from "react";
import { ProductSection } from "./productstyle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import getImagePath from "../../assets/ImageUtils";

const Product = () => {
  var settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <ProductSection className="section product_section" id="products">
        <div className="container">
          <div className="product_heading">
            <h4>Our Products</h4>
          </div>
          <Slider {...settings}>
            <div>
              <div className="product-content">
                <div className="stack-ui">
                  <div className="stack-ui-content">
                    <h5>Stack UI Library</h5>
                    <p>
                      At Stack UI, we want to create a cohesive experience for
                      our users. This system will enable teams to be more
                      efficient, have a shared language across the company, and
                      focus deeper on solving user problems.Whenever UI
                      developer developed the User interface. They use various
                      tags and prototypes and every time they face similar
                      problems.Our goal are rectify those issue and get
                      resolved. We are providing such type of code which support
                      all the browsers.
                    </p>
                    <div className="stack-ui-btn">
                      <a href="https://stackui.tech/library/react/">
                        <button>Go to Library</button>
                      </a>
                    </div>
                  </div>
                  <div className="slider-part">
                    <div className="right-side-img">
                      <div className="inner-img">
                        <img
                          className="inner-img-1"
                          src={getImagePath("image/Vector-6.png")}
                          alt="img"
                        />
                        <img
                          className="inner-img-2"
                          src={getImagePath("image/Vector-7.png")}
                          alt="img"
                        />
                        <img
                          className="outer-img-1"
                          src={getImagePath("image/ui-lib-1.png")}
                          alt="img"
                        />
                        <img
                          className="outer-img-2"
                          src={getImagePath("image/ui-lib2.png")}
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="product-content">
                <div className="stack-ui">
                  <div className="stack-ui-content">
                    <h5>Stack UI Library</h5>
                    <p>
                      At Stack UI, we want to create a cohesive experience for
                      our users. This system will enable teams to be more
                      efficient, have a shared language across the company, and
                      focus deeper on solving user problems.Whenever UI
                      developer developed the User interface. They use various
                      tags and prototypes and every time they face similar
                      problems.Our goal are rectify those issue and get
                      resolved. We are providing such type of code which support
                      all the browsers.
                    </p>
                    <div className="stack-ui-btn">
                      <a href="https://stackui.tech/library/react/">
                        <button>Go to Library</button>
                      </a>
                    </div>
                  </div>
                  <div className="slider-part">
                    <div className="right-side-img">
                      <div className="inner-img">
                        <img
                          className="inner-img-1"
                          src={getImagePath("image/Vector-6.png")}
                          alt="img"
                        />
                        <img
                          className="inner-img-2"
                          src={getImagePath("image/Vector-7.png")}
                          alt="img"
                        />
                        <img
                          className="outer-img-1"
                          src={getImagePath("image/ui-lib-1.png")}
                          alt="img"
                        />
                        <img
                          className="outer-img-2"
                          src={getImagePath("image/ui-lib2.png")}
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="product-content">
                <div className="stack-ui">
                  <div className="stack-ui-content">
                    <h5>Stack UI Library</h5>
                    <p>
                      At Stack UI, we want to create a cohesive experience for
                      our users. This system will enable teams to be more
                      efficient, have a shared language across the company, and
                      focus deeper on solving user problems.Whenever UI
                      developer developed the User interface. They use various
                      tags and prototypes and every time they face similar
                      problems.Our goal are rectify those issue and get
                      resolved. We are providing such type of code which support
                      all the browsers.
                    </p>
                    <div className="stack-ui-btn">
                      <a href="https://stackui.tech/library/react/">
                        <button>Go to Library</button>
                      </a>
                    </div>
                  </div>
                  <div className="slider-part">
                    <div className="right-side-img">
                      <div className="inner-img">
                        <img
                          className="inner-img-1"
                          src={getImagePath("image/Vector-6.png")}
                          alt="img"
                        />
                        <img
                          className="inner-img-2"
                          src={getImagePath("image/Vector-7.png")}
                          alt="img"
                        />
                        <img
                          className="outer-img-1"
                          src={getImagePath("image/ui-lib-1.png")}
                          alt="img"
                        />
                        <img
                          className="outer-img-2"
                          src={getImagePath("image/ui-lib2.png")}
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </ProductSection>
    </>
  );
};
export default Product;
