import styled from "styled-components";

export const CounterSection = styled.section`

 .counter-content {
  border: 1px solid #EAEAEA;
  border-radius: 26px;
  margin: 94px 0 0 0;
}

.counter-part {
  padding: 39px 108px 40px 54px;
}

.counter-number {
  h4 {
    font-weight: 800;
    font-size: 40px;
    line-height: 34px;
    color: #000000;
  }

  p {
    font-size: 18px;
    line-height: 34px;
    color: #9495A1;
  }
}

.dot span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 32px 0 0px 0;
}

.pink-dot {
  background: #FF999A;
}

.counter-area {
  display: flex;
  justify-content: space-between;
}

.green-dot {
  background-color: #9FFFC5;
}

.purple-dot {
  background-color: #E59AFF;
}
@media (max-width: 768px) {
    .counter {
        padding-top: 0px;
    } 
    .dot span {
        margin-right: 30px;
    }
    .counter-part {
        padding: 39px 0 40px 34px;
    }
    p {
        line-height: 26px !important;
      }
};

@media (max-width: 600px) {
    .counter-area{
        flex-wrap: wrap;
        text-align: center;
    }
    .counter-number {
        padding: 20px 0;
        width:100%;
    }
    .dot {
        width: 83%;
        margin: 0 auto;
    }
    .dot span {
        margin:0px;
    }
};`
