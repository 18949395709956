import { createClient } from "contentful";

const client = createClient({
  space: "tlkhf4tf1fyy",
  accessToken: "-xr5DtlFntf5W-QA_2EAhmT8nde9W55SyZJevWoF7xc",
});

export async function getIndustries() {
  const response = await client.getEntries({
    content_type: "caseStudyCustomPage",
  });
  const FilterData = response.items.map((element, index) => {
    const IndustryDetail = element.fields.industryType;
    return IndustryDetail;
  });
  return [...new Set(FilterData)];
}
export async function getFullInfo() {
  const response = await client.getEntries({
    content_type: "caseStudyCustomPage",
  });
  const FilterData = response.items.map((element, index) => {
    const IndustryDetail = element?.fields;
    return IndustryDetail;
  });

  return [...new Set(FilterData)];
}

export async function getServices() {
  const response = await client.getEntries({
    content_type: "caseStudyCustomPage",
  });
  const FilterData = response.items.map((element, index) => {
    const IndustryType = element.fields.serviceType;
    return IndustryType;
  });

  return [...new Set(FilterData)];
}

export async function getCaseStudyData() {
  const response = await client.getEntries({
    content_type: "caseStudyCustomPage",
  });
  const FilterData = response.items.map((element, index) => {
    console.log("res", response.items)
    const IndustryType = element.fields;
    console.log(response, element, IndustryType, "Data found")
    return IndustryType;
  });

  return [...new Set(FilterData)];
}
