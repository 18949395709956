import React from "react";
import Slider from "react-slick";
import { LogoSection } from "./logostyle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import getImagePath from "../../assets/ImageUtils";

const Logo = () => {
  var settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    pauseOnHover: true,
  };
  return (
    <>
      <LogoSection className="logo-section">
        <div className="container">
          <Slider className="logo" {...settings}>
            <div className="logo-icons circle-image">
              <img src={getImagePath("image/iso.png")} alt="img" />
            </div>
            <div className="logo-icons">
              <img
                className="nasscom-logo"
                src={getImagePath("image/Nasscom-logo-svg.png")}
                alt="logo"
              />
            </div>
            <div className="logo-icons circle-image">
              <img src={getImagePath("image/GDPR.png")} alt="logo" />
            </div>
            <div className="logo-icons">
              <img
                className="node-logo"
                src={getImagePath("image/node-js.png")}
                alt="logo"
              />
            </div>
            <div className="logo-icons circle-image">
              <img src={getImagePath("image/iso.png")} alt="img" />
            </div>
            <div className="logo-icons">
              <img
                className="meteor-logo"
                src={getImagePath("image/meteor.png")}
                alt="img"
              />
            </div>
            <div className="logo-icons circle-image">
              <img src={getImagePath("image/GDPR.png")} alt="logo" />
            </div>
            <div className="logo-icons">
              <img
                className="meteor-logo"
                src={getImagePath("image/Nasscom-logo-svg.png")}
                alt="img"
              />
            </div>
          </Slider>
        </div>
      </LogoSection>
    </>
  );
};
export default Logo;
