import styled from "styled-components";
import { theme } from "../../assets/styles/theme";

export const MainFooter = styled.footer`
    background-color: #2D55DB;
    .stackpart {
        display: flex;
        padding: 3.5rem 0;
        .stackuis {
            padding: 0 1rem;
            h3 {
                font-size: 1.875rem;
                font-style: normal;
                font-weight: 400;
                color: ${theme.colors.white.shade0};
                span.ui {
                  color: ${theme.fonts.headingOrange};
                }
            }
        }
        .stackpara {
            width: 42%;
            padding-left: 3.75rem;
            p {
              font-size: 1.125rem;
              color: ${theme.colors.white.shade0};
            }
        }
        .footer-logo {
            img {
                &.footer-logo1 {
                    width: 2rem;
                }
                &.footer-logo2 {
                    width: 2.625rem;
                    position: absolute;
                    margin: 1.125rem 0px 0 -2.5rem;
                }
            }
        }
    }
    .border-line {
        border-bottom: 1px solid ${theme.colors.white.shade0};
        max-width: 80%;
        opacity: 0.1;
    }
    .footer-list {
        .container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .footer-img {
                position: relative;
                img {
                    &.footer-img1 {
                        width: 12.625rem;
                        position: relative;
                    }
                    
                    &.footer-img2 {
                        width: 10.25rem;
                        position: absolute;
                        top: -5.3125rem;
                        left: 1.875rem;
                    }
                }
            }
            .footer-item {
                display: flex;
                justify-content: space-between;
                width: 72%;
                padding: 2.875rem 0;
                h5 {
                    font-size: 1.5rem;
                    color: ${theme.colors.white.shade0};
                    font-style: normal;
                    font-weight: 500;
                }
              
                li {
                    font-size: 1rem;
                    color: ${theme.colors.white.shade0};
                    line-height: 2;
                    a {
                        color: ${theme.colors.white.shade0};
                    }
                }
            }
        }
    }
    .bottom-footer {
        background: #2750DA;
        h6 {
            padding: 1rem 0;
            text-align: center;
            font-size: 1rem;
            line-height: 2;
            color: #A3AAC0;
            font-weight: 400;
        }
    }
} 

@media (max-width: 768px) {
    .stackpara {
        width: 71%;
    }
    .footer-item {
        min-width: 100%;
    }
    .footer-img {
        display: none;
    }
}

@media (max-width: 600px) {
    .footer-item {
        display: flex;
        flex-wrap: wrap !important;
    }
    .footer-contactus{
        padding:16px 0 0 0;
    }
    .stackpara {
        width: 100% !important;
        padding-left: 3rem !important;
    }
    .stackpart {
        display: flex;
        flex-wrap: wrap;
    }
    .border-line {
        max-width: 95%- !important;
    }
}
`;
