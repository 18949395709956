import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";
//import { mq } from "./index";

export const GlobalStyleDark = createGlobalStyle`
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color:${theme.colors.white.shade0};
    }

    header{        
        background: ${theme.colors.white.shade0};
        color: ${theme.colors.blue1.shade3};
        p{
            color: ${theme.colors.blue.shade3};
        }
    } 
    .popular-feature-main .feature-group-icon .feature-list p {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.5;
        color:white !important;
        margin-top: 1.25rem;
    }  
    
    .small {
        background: linear-gradient(81.71deg,rgb(0,29,120) -2.91%,rgb(23 31 63) 80.82%);
    }

    body{
        background: #181F3F;
    }
    .header{
        background: rgb(20,25,53);
        background: linear-gradient(90deg, rgba(20,25,53,1) 0%, rgba(22,43,110,1) 100%);
        .ui-development{
            .box{
                .box-right, .box-left{
                    background:#162670;
                }
                .box-left-white{
                    background:#0D214F;
                }
            }
        }
    }

        .webdevelopment{
            background:#141935;
            .left-content-web{
                .web-heading{
                    h3,p{
                        color:#fff;
                    }
                } 
            }
        
            .right-content-web{
                .circle-group{
                    .circle{
                        .dotsWrapper{
                            .circle-title{
                                background:#181F3F;
                                color:#fff;
                            }
                        }
                    }
                }
            }    
        }

        .counter{
            .counter-number{
                h4{
                color:#fff;
                }
            }
            .counter-content{
                border-color:#3D67EF
            }
        }

        .product_section{
            .product_heading{
                h4{
                    color:#fff;
                }
            }
            .stack-ui{
                .stack-ui-content{
                    h5{
                        color:#fff
                    }
                } 
            } 
        }

        .features_section{
            .feature-heading{
                h4{
                    color:#fff;
                }
            }

            .popular-feature-main{
                .feature-group-icon{
                    .feature-list{
                        h5{
                            color:#fff
                        }
                    }
                }
            }
        }

        .form_section{
            background:#141F45;
            .form-container{
                .form-content{
                    background:rgba(30, 38, 76, 0.85);
                    label{
                        color:#fff;
                    }
                    .form-control{
                        background:none;
                    }
                }
            }
        }
        footer.footer{
            background:#1E264C;
            .bottom-footer{
                background:#141F45
            }
        }

        .form_section .form-container .form-content {
            border: 1px solid #3D67EF;
        }

        .stackBlogContainer{
            .banner-img{
                border: 0.5px solid #3D67EF;
                &:after{
                    content:'';
                    background:rgb(1 17 68 / 87%);
                    width:100%;
                    height:100%;
                    position:absolute;
                }

                .stack-blog{
                    z-index:1;
                    h3,p{
                        color:#fff
                    }
                }
            }
            
        }

        .product_section .slick-dots li button:before {
            background: white;
        }

        .container .logo .slick-dots li button:before {
            background: white;
        }

        .liwQeC .form-heading h4 {
            color: white;
        }

        input#firstname-7ecf6d57-b46a-4601-80ac-65823877eca7 {
            background: transparent;
            border: 1px solid #64748B;
        }

        input#phone-7ecf6d57-b46a-4601-80ac-65823877eca7{
            background: transparent;
            border: 1px solid #64748B;
        }

        input#email-7ecf6d57-b46a-4601-80ac-65823877eca7{
            background: transparent;
            border: 1px solid #64748B;
        }

        textarea#message-7ecf6d57-b46a-4601-80ac-65823877eca7{
            background: transparent;
            border: 1px solid #64748B;
        }

        .header-section#header-sroll.small {
            padding: 0.75rem 0rem;
            background: linear-gradient(81.71deg, rgb(0, 29, 120) -2.91%, rgb(23 31 63) 80.82%)!important;
        }
        
`;


