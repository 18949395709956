import styled from "styled-components";
import { theme } from "../../assets/styles/theme";

export const FeatureSection = styled.section`
    padding: 6rem 0rem 0rem;
    .feature-heading {
        h4 {
            font-weight: 800;
            font-size: 2.5rem;
            line-height: 1.5;
            text-transform: capitalize;
            color: ${theme.fonts.headingblack};
        }
    }
    .popular-feature-main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;
        padding: 4.375rem 0 8.375rem;
        .feature-group-icon {
            display: flex;
            width: 40%;
            .feature-icons1 {
                img {
                    width: 3.375rem;
                }  
            }
            .feature-icons2 {
                img {
                    width: 2.8125rem;
                    margin: 1.3125rem -2.5625rem;
                }
            }
            img {
                &.performance {
                    margin: -2px 0px 0 -51px;
                }
              
                &.support-icon {
                    margin: 10px -37px;
                }
              
                &.ease {
                    margin: 4px -35px;
                }
              
                &.free {
                    margin: 0px -30px;
                }
              
                &.frequent {
                    margin: -5px -33px;
                }
            }
            .feature-list {
                padding: 0 0px 3rem 2.25rem;
                &:after {
                    content: '';
                    width: 70%;
                    display: block;
                    border-bottom: 2px dashed rgba(207, 207, 207, 0.5);
                    margin-top: 3rem;
                }
                h5 {
                  font-weight: 600;
                  font-size: 1.5rem;
                  line-height: 1.5;
                  text-transform: capitalize;
                  color: ${theme.fonts.headingblack};
                }
            
                p {
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 1.5;
                    color:#333;
                    margin-top: 1.25rem;
                }
                .blank-line {
                    padding: 1rem 0 3.125rem 0;
                    p {
                        font-weight: 200;
                        font-size: 1.875rem;
                        line-height: 1.5;
                        margin-top: 0rem;
                        text-transform: uppercase;
                        color: rgba(207, 207, 207, 0.5);
                    }
                }
            }
        }
    }
}

@media (max-width:600px) {
    .feature-group-icon {
        width: 100% !important;
    }
}
`;
