import React, { useState, useEffect } from "react";
import { MainHeader } from "./headerStyle";
import { Typewriter } from "react-simple-typewriter";
import SwitchBtn from "../../component/ToggleBtn/switch";
import $ from "jquery";
import ScrollIntoView from "react-scroll-into-view";
import { Link } from "@mui/material";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";

import {
  Link as Links,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Drawer from "@mui/material/Drawer"; // Import Drawer from Material-UI
import MenuIcon from "@mui/icons-material/Menu"; // Import Menu icon from Material-UI
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import getImagePath from "../../assets/ImageUtils"; // Adjust path as per your project structure

const Header = ({ Title, Description, image }) => {
  const params = useLocation();
  const navigate = useNavigate();
  const { pathname } = params;
  const [getRandom, setRandom] = useState("");
  const { REACT_APP_PUBLIC_URL } = process.env;
  const [animate, setAnimate] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setAnimate("animate");
    }, 700);
  }, []);

  $(window).scroll(function () {
    var sc = $(window).scrollTop();
    if (sc > 100) {
      $("#header-sroll").addClass("small");
    } else {
      $("#header-sroll").removeClass("small");
    }
  });

  $(document).ready(function () {
    $("a").on("click", function (event) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          800,
          function () {
            window.location.hash = hash;
          }
        );
      }
    });
  });

  useEffect(() => {
    const scrollToElement = () => {
      const targetId = localStorage.getItem("scrollID");
      const targetElement = targetId && document.querySelector(targetId);

      if (targetElement) {
        const topPos =
          targetElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: topPos, behavior: "smooth" });
      }
    };

    const delay = 100;
    const timeoutId = setTimeout(scrollToElement, delay);

    return () => clearTimeout(timeoutId);
  }, [getRandom, pathname]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Check if the screen width is less than a certain breakpoint (e.g., 600px)
  const isMobile = useMediaQuery("(max-width:600px)");

  // if (!isMobile) {
  // Render the original header for desktop devices
  return (
    <MainHeader className="header">
      <header style={{ position: "relative" }}>
        <div id="header-sroll" className="header-section">
          <div className="container">
            <div className="header-content">
              <div
                onClick={() => navigate("/")}
                className="right-content-header"
              >
                <div className="header-logo">
                  <img
                    className="header-logo1"
                    src={getImagePath("image/Rectangle-3.png")}
                    alt="Logo"
                  />
                  <img
                    className="header-logo2"
                    src={getImagePath("image/Rectangle-12.png")}
                    alt="img"
                  />
                </div>
                <div className="stackuis">
                  <h3>
                    Stack <span className="ui">UI</span>
                  </h3>
                </div>
              </div>
              {!isMobile ? (
                <div className="left-content-header">
                  <div className="header-menu">
                    <nav>
                      <Links to={"/"}>
                        <div
                          className="a"
                          onClick={() => [
                            localStorage.setItem("scrollID", "#home"),
                            setRandom(Math.random()),
                          ]}
                        >
                          Home
                        </div>
                      </Links>
                      <Links to={"/"}>
                        <div
                          className="a"
                          onClick={() => [
                            localStorage.setItem("scrollID", "#aboutus"),
                            setRandom(Math.random()),
                          ]}
                        >
                          About Us
                        </div>
                      </Links>
                      <Links
                        to={"/case-study"}
                        style={{ textDecorationLine: "none" }}
                      >
                        <div
                          onClick={() => [
                            localStorage.setItem("scrollID", "#home"),
                            setRandom(Math.random()),
                          ]}
                          className="a"
                        >
                          Case Study
                        </div>
                      </Links>
                      <Links to={"/"}>
                        <div
                          onClick={() => [
                            localStorage.setItem("scrollID", "#products"),
                            setRandom(Math.random()),
                          ]}
                          className="a"
                        >
                          Products
                        </div>
                      </Links>
                      <Links to={"/"}>
                        <div
                          onClick={() => [
                            localStorage.setItem("scrollID", "#features"),
                            setRandom(Math.random()),
                          ]}
                          className="a"
                        >
                          Features
                        </div>
                      </Links>
                      <Links to={"/"}>
                        <div
                          onClick={() => [
                            localStorage.setItem("scrollID", "#contactus"),
                            setRandom(Math.random()),
                          ]}
                          className="a"
                        >
                          Contact Us
                        </div>
                      </Links>
                    </nav>
                    <SwitchBtn />
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <SwitchBtn />
                  <div style={{ marginBottom: "5px" }}>
                    <Button onClick={toggleDrawer}>
                      <MenuIcon
                        style={{
                          fontSize: 30,
                          color: "white",
                          width: "auto",
                          height: "auto",
                        }}
                      />{" "}
                    </Button>
                  </div>

                  <Drawer open={drawerOpen} onClose={toggleDrawer}>
                    <Box
                      sx={{ width: 250 }}
                      role="presentation"
                      onClick={toggleDrawer}
                    >
                      <List>
                        {[
                          "Home",
                          "About Us",
                          "Case Study",
                          "Products",
                          "Features",
                          "Contact Us",
                        ].map((text, index) => (
                          <ListItem
                            key={text}
                            style={{ color: "rgba(0, 0, 0, 0.87)" }}
                            disablePadding
                            component={Links}
                            to={text === "Case Study" ? "/case-study" : "/"}
                          >
                            <ListItemButton>
                              <ListItemText
                                primary={text}
                                onClick={() => {
                                  const sectionId = text
                                    .toLowerCase()
                                    .replace(/\s/g, ""); // Get section ID from text
                                  localStorage.setItem(
                                    "scrollID",
                                    `#${sectionId}`
                                  ); // Set scrollID in localStorage
                                  setRandom(Math.random()); // Force re-render to scroll to the section
                                  if (isMobile) {
                                    const targetElement =
                                      document.querySelector(`#${sectionId}`);
                                    if (targetElement) {
                                      const topPos =
                                        targetElement.getBoundingClientRect()
                                          .top + window.pageYOffset;
                                      window.scrollTo({
                                        top: topPos,
                                        behavior: "smooth",
                                      });
                                    }
                                  }
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Drawer>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <section className="ui-development" id="home">
        <div className="box">
          <div className="box-right"></div>
          <div className="box-left"></div>
          <div className="box-left-white"></div>
        </div>
        <div className="container">
          <div className={"ui-development-content " + animate}>
            <div className="ui-development-right">
              <h1>
                {Title ? Title : "UI Development"}
                {!Title && (
                  <strong>
                    {" "}
                    <Typewriter
                      words={["Solutions", "Eat", "Sleep", "Code", "Repeat!"]}
                      loop={0}
                      cursor
                      cursorStyle="_"
                      typeSpeed={150}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    />
                  </strong>
                )}
              </h1>
              {Description && <p> {Description}</p>}
            </div>
            <div className="ui-development-left">
              <img
                className="roomElement painting"
                src={getImagePath("image/E1.svg")}
                alt="img"
              />
              <img
                className="roomElement bookself"
                src={getImagePath("image/E2.svg")}
                alt="img"
              />
              <img
                className="roomElement flowerpot"
                src={getImagePath("image/E3.svg")}
                alt="img"
              />
              <img
                className="roomElement bricks"
                src={getImagePath("image/E4.svg")}
                alt="img"
              />
              <img
                className="boyElement"
                style={{ opacity: 0.8 }}
                src={getImagePath("image/tab.png")}
                alt="img"
              />
            </div>
          </div>
        </div>
      </section>
    </MainHeader>
  );
};

export default Header;
