import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";
//import { mq } from "./index";

export const GlobalStyleLight = createGlobalStyle`
    body{        
        background: ${theme.colors.white.shade0};
    }
    .header{        
        background: ${theme.colors.blue.shade3};
        color: ${theme.colors.white.shade0};
    }  
`;


