import React from "react";
import Blog from "../blog/blog";
import Counter from "../counter/counter";
import Feature from "../features/features";
import Footer from "../footer/footer";
import Form from "../form/form";
import Casestudies from "../caseStudies/caseStudies";
import CaseStudiespage from "../../caseStudiespage/caseStudiespage";

import Header from "../Header/header";
import Logo from "../logo/logo";
import Product from "../Products/product";
import Web from "../web development/web-development";

const Landing = (props) => {
  const { theme } = props;
  return (
    <>
      {/* <Casestudies /> */}
      <Header />
      <Web />
      <Counter />
      <Product />

      <Feature theme={theme} />
      <Blog />
      <Logo />
      <Form />
      <Footer />
    </>
  );
};

export default Landing;
