import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./routes";
import { GlobalStyle } from "./assets/styles/globalStyle";
import Casestudies from "./module/caseStudies/caseStudies";
import ThemeContext from "../src/context/themeContext";

function App() {
  console.log(process.env.REACT_APP_PUBLIC_URL, "Testing......")
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <ThemeContext>
          <Router />
        </ThemeContext>
      </BrowserRouter>
    </>
  );
}

export default App;
