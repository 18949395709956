
export const theme = {
  colors: {
    primary: "",
    secondary: "",
    white: {
      
   
      shade3: "#2a323a",
      shade0: "#ffffff",
      // shade1: "#f5f5f5",
      // shade2: "#fafafa",
    },
    black: {
      shade0: "#000000",
      shade1: "#080808",
      shade2: "#4b4949",
      shade3: "#3c4751",
    },
    grey: {
      shade0: "#b3b3b3",
      shade1: "#757575",
      shade2: "#222222",
      shade3: "#333333",
      shade4: "#f6f6f6",
      shade5: "#cccccc",
      shade6: "#172b4c",
    },
    blue: {
  
      // shade3: "#2a323a",
      shade3: "linear-gradient(81.71deg , #162B6E -2.91%, #2D55DB 80.82%)",
   
      
    },
    blue1: {
  
      // shade3: "#2a323a",
      shade3: " linear-gradient(81.71deg, rgb(0, 29, 120) -2.91%, rgb(23 31 63) 80.82%);",
   
      
    },
  },
  fonts: {
    topHeading: {
      min: "1rem",
      max: "2.5rem",
    },
    primaryHeading: "",
    secondaryHeading: "",
    defaultFont: "16px",
    paragraphTextColor: "#9495A2",
    headingblack: "#0E1D4B",
    headingBlue: "#2D55DB",
    headingOrange: "#FD891E",
    inputPlaceHolder: "#D8D9DD",
    label: "#525254"
  },
  backgroundColor: {
    buttonBG: "#3D67EF"
  },
  radius: {
    defaultRadius: "10px",
    buttonRadius: "4px",
    iconRadius: "100%",
  },
  shadow: {
    defaultShadow: "0 0 30px 0 rgb(82 63 105 / 5%)",
    hoverShadow: "0 14px 24px rgb(0 0 0 / 20%)",
  },
};
